import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_setting: EntityUrl = {
    get savePass() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/setting/savePasswordRule`;
    },
    get getPass() {
        return `${urlManger.getServiceUrl("user")}/system/dict/findSysDictType`;
    },
    get getOrgPassRule() {
        return `${urlManger.getServiceUrl("user")}/system/setting/findPassRule`;
    },
    get saveEmailInfo() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/setting/saveEmailConfig`;
    },
    get testSendEmail() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/setting/sendTestEmail`;
    },
    get getEmailInfo() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/setting/findSystemEmail	`;
    },

    get saveSystemInfo() {
        return `${urlManger.getServiceUrl("user")}/system/setting/systemInfo`;
    },
    get getSystemInfo() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/setting/findSystemInfo`;
    },
    get downloadUrl() {
        return `${urlManger.getServiceUrl("user")}/system/file/download`;
    },
};
