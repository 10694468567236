import { Metadata } from "@intasect/platform-core";
export const settingMetadata: Metadata[] = [
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "企业id",
    },
    {
        fieldName: "symbol",
        fieldType: "number",
        title: "特殊字符",
    },
    {
        fieldName: "capitalLetters",
        fieldType: "number",
        title: "大写字母",
    },
    {
        fieldName: "lowercase",
        fieldType: "number",
        title: "小写字母",
    },
    {
        fieldName: "number",
        fieldType: "number",
        title: "数字",
    },
    {
        fieldName: "length",
        fieldType: "number",
        title: "密码长度",
    },
];
