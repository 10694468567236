import { Metadata } from "@intasect/platform-core";
export const messageMetadata: Metadata[] = [
    {
        fieldName: "keyId",
        fieldType: "string",
        title: "id"
    },
    {
        fieldName: "eventName",
        fieldType: "string",
        title: "事件名称"
    },
    {
        fieldName: "eventCode",
        fieldType: "string",
        title: "事件编码"
    },
    {
        fieldName: "fromOrgId",
        fieldType: "string",
        title: "组织编码（来源）"
    },
    
    {
        fieldName: "fromOrgName",
        fieldType: "string",
        title: "组织名称（来源）"
    },
    {
        fieldName: "fromOrgCode",
        fieldType: "string",
        title: "组织编码（来源）"
    },
    {
        fieldName: "toOrgName",
        fieldType: "string",
        title: "组织名称（目标）"
    },
    {
        fieldName: "toOrgCode",
        fieldType: "string",
        title: "组织编码（目标）"
    },
    {
        fieldName: "actionUri",
        fieldType: "string",
        title: "目标地址"
    },
    {
        fieldName: "tags",
        fieldType: "string",
        title: "消息标签"
    },
    {
        fieldName: "content",
        fieldType: "string",
        title: "消息内容"
    },
    {
        fieldName: "sendTime",
        fieldType: "string",
        title: "添加的时间"
    },
    {
        fieldName: "sendIp",
        fieldType: "string",
        title: "推送ip"
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "消息状态"
    },
    {
        fieldName: "result",
        fieldType: "string",
        title: "消息结果"
    },
    {
        fieldName: "processTime",
        fieldType: "string",
        title: "操作时间"
    },
    {
        fieldName: "groupId",
        fieldType: "string",
        title: "组id"
    },
    {
        fieldName: "sendTime1",
        fieldType: "string",
        title: "发送时间开始"
    },
    {
        fieldName: "sendTime2",
        fieldType: "string",
        title: "发送时间结束"
    },
    {
        fieldName: "processTime1",
        fieldType: "string",
        title: "执行时间开始"
    },
    {
        fieldName: "processTime2",
        fieldType: "string",
        title: "执行时间结束"
    }
];
