import { DicdataEntity } from "@/entities/dicdata/dicdataEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_dicdata } from "@/controllers/dicdata/dicdataUrls";
export class DicdataController extends EntityController<DicdataEntity> {
    constructor(request: IRequest) {
        super(request, url_dicdata, "字典数据");
    }
    static readonly entityName = "dicdata";
    async dicDataAdd(data: any) {
        const res = await this.request({
            action: "add",
            url: url_dicdata.save,
            method: "post",
            data,
            showSuccess: true,
            applyLoading: true,
        });
        return res;
    }
    async dicDataUpdate(data: any) {
        const res = await this.request({
            action: "add",
            url: url_dicdata.update,
            method: "post",
            data,
            showSuccess: true,
            applyLoading: true,
        });
        return res;
    }
    async dicDataDelete(data: any) {
        const res = await this.request({
            action: "del",
            url: url_dicdata.delete,
            method: "post",
            data,
            showSuccess: true,
        });
        return res;
    }
}
