import { PostEntity } from "@/entities/post/postEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_post } from "@/controllers/post/postUrls";
import { urlManger } from "@/url/common";
export class PostController extends EntityController<PostEntity> {
    constructor(request: IRequest) {
        super(request, url_post, "岗位");
    }
    static readonly entityName = "post";
    async findPostTree(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/post/findPostTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }

    async postDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/post/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async create(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/post/add`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    async Update(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/post/update`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    async getTenantsTree(data: { tenantsName?: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
}
