import axios from "axios";
import { dayJSLangResolver } from "./utils";

export const antdLoader = async (lang: string) => {
    //console.log(`${WEBPACK_GLOBAL.BASE_URL}${WEBPACK_GLOBAL.LOCALE_URL}/ant-design-vue/${lang}.json`)
    const data = await axios.get(
        `${__webpack_public_path__}${WEBPACK_GLOBAL.LOCALE_URL}/ant-design-vue/${lang}.json`
    );
    return data.data;
};

export const dayJSLoader = async (lang: string) => {
    const data = await axios.get(
        `${__webpack_public_path__}${
            WEBPACK_GLOBAL.LOCALE_URL
        }/dayjs/${dayJSLangResolver(lang)}.json`
    );
    return data.data;
};
