import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_menus: any = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/menus/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/api/menus/save`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/api/menus/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/menu/findMenuTree`;
    },
    get list() {
        return `${urlManger.getServiceUrl("user")}/system/menu/findMenuTree`;
    },
};
