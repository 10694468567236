import {
    App,
    defineComponent,
    h,
    onMounted,
    reactive,
    ref,
    shallowRef,
    Ref,
    ComputedRef,
    computed,
} from "vue";
import {
    createRouter,
    RouterOptions,
    Router,
    RouteComponent,
} from "vue-router";
import { MenuDef, MenuType, RouteData, VueRouteData } from "./declare";
import { Button, Spin, Result } from "@intasect/ant-design-vue";
import { PermissionSubAppRoleData } from "@/service/declare";
import { SubAppDef } from "@intasect/platform-core";
import { MicroAppRegister } from "@/microApp/MicroAppRegister";
import { subAppPathPrefix } from "@/config/subApp";
import { RouterChannel } from "./RouterChannel";

const noop = () => null;
export class RouterManager {
    public router: Router;
    public routes = reactive<VueRouteData[]>([]);
    private _menuTree: Ref<MenuDef[]>;
    private _cacheRoutes: Ref<RouteData[]>;
    private _cacheRouteNames: ComputedRef<string[]>;
    private _finalCacheRoutes: ComputedRef<RouteData[]>;
    private _activeRoute: Ref<string>;
    private _enableCache: Ref<boolean>;
    private _defaultEntiry: string;
    constructor(
        vue: App,
        private parentName: string,
        public staticRoutes: VueRouteData[],
        public staticMenus: MenuDef[],
        private options: RouterOptions,
        enableCache: boolean = true,
        public readonly channel: RouterChannel = new RouterChannel()
    ) {
        this.router = createRouter(options);
        this._menuTree = ref([]);
        this._cacheRoutes = ref([]);
        this._defaultEntiry = `${
            this.parentName === "" ? "" : `/${parentName}`
        }/home`;
        this._activeRoute = ref(defaultRouteEntry);
        this._enableCache = ref(enableCache);
        this._cacheRouteNames = computed(() => {
            if (this._enableCache.value) {
                const set = new Set<string>();
                this.cacheRoutes.forEach(route => {
                    set.add(route.name);
                });
                return Array.from(set);
            }
            return [];
        });
        this._finalCacheRoutes = computed(() => {
            if (this._enableCache.value) {
                return this._cacheRoutes.value;
            }
            return [];
        });
        vue.use(this.router);
    }

    go = (menuDef: MenuDef, microAppRegister: MicroAppRegister) => {
        if (menuDef.isSubApp) {
            const subAppDef = microAppRegister.findSubAppDef(menuDef.subAppId!);
            if (subAppDef) {
                this.router.push(
                    `${subAppPathPrefix}${subAppDef.subAppCode}/${menuDef.path}`
                );
            } else {
                console.warn(`can't find subAppdef with ${menuDef.subAppId}`);
            }
        } else {
            this.router.push(`/main/${menuDef.path}`);
        }
    };

    getMenuDef = (id: string): MenuDef | undefined => {
        return this.findMenuDef(id, this.menuTree);
    };

    private findMenuDef = (
        id: string,
        defs: MenuDef[]
    ): MenuDef | undefined => {
        for (const item of defs) {
            if (item.id === id) {
                return item;
            }
            if (item.children) {
                const result = this.findMenuDef(id, item.children);
                if (result) {
                    return result;
                }
            }
        }
    };

    genMenuTree = (permission: PermissionSubAppRoleData[]) => {
        this._menuTree.value = this.genPermissionTree(
            permission
                .map<MenuDef>(p => {
                    return {
                        id: p.id,
                        icon: p.icon,
                        path: p.path,
                        type: p.menuType,
                        title: p.menuName,
                        order: Number(p.orderNum),
                        parentId: p.parentId,
                        isSubApp: !!p.subAppId,
                        subAppId: p.subAppId,
                        visible: p.visible,
                    };
                })
                .concat(this.staticMenus)
                .sort((a, b) => a.order - b.order)
        );
    };

    genPermissionTree = (menusDefs: MenuDef[], parent?: MenuDef) => {
        const parentId = parent ? parent.id : "0";
        return menusDefs
            .filter(a => a.parentId === parentId)
            .map<MenuDef>(def => {
                def.children = this.genPermissionTree(menusDefs, def);
                def.parent = parent;
                return def;
            });
    };

    buildStaticRoutes() {
        const newRoutes: VueRouteData[] = [];
        this.staticRoutes.forEach(r => {
            const data = this.genRoute(this.parentName, r);
            newRoutes.push(r);
            this.router.addRoute(data[0], data[1]);
        });
        this.staticRoutes.push(...newRoutes);
    }

    genRoute = (path: string, route: VueRouteData): any[] => {
        if (path) {
            return [path, route];
        } else {
            if (route.path[0] !== "/") route.path = "/" + route.path;
            return [route];
        }
    };

    buildDynamicRoutes(permission: PermissionSubAppRoleData[]) {
        try {
            this.genMenuTree(permission);
            const newRoutes: VueRouteData[] = [];
            const router = createRouter(this.options);
            this.staticRoutes.forEach(r => {
                const data = this.genRoute(this.parentName, r);
                router.addRoute(data[0], data[1]);
            });
            permission
                .filter(
                    a =>
                        !a.subAppId &&
                        !!a.path &&
                        a.menuType !== MenuType.button
                )
                .forEach(r => {
                    const dynamicRoute: VueRouteData = {
                        path: `${r.path}`,
                        name: r.path,
                        component: lazyLoader(
                            () => import(`../views/${r.component}.vue`),
                            r.path
                        ),
                        meta: {
                            title: r.menuName,
                            id: r.id,
                            isSubApp: false,
                        },
                    };
                    newRoutes.push(dynamicRoute);
                    const data = this.genRoute(this.parentName, dynamicRoute);
                    this.router.addRoute(data[0], data[1]);
                    router.addRoute(data[0], data[1]);
                });
            this.routes.push(...newRoutes);
            //@ts-ignore
            this.router.matcher = router.matcher;
        } catch (e) {
            console.log(e);
        }
    }

    buildMicroAppRoutes(
        subAppDefs: SubAppDef[],
        roleData: PermissionSubAppRoleData[]
    ) {
        subAppDefs.forEach(subApp => {
            this.routes.push(
                ...roleData
                    .filter(a => a.subAppId === subApp.id)
                    .map<VueRouteData>(a => ({
                        name: subApp.subAppCode,
                        path: a.path,
                        component: noop,
                        meta: {
                            id: a.id,
                            isSubApp: true,
                            subAppId: subApp.id,
                            subAppRootPath: `${subApp.subAppCode}/${a.path}`,
                            subAppCode: subApp.subAppCode,
                            title: a.menuName,
                        },
                    }))
            );
        });
    }

    addMicroAppRoute(
        subAppCode: string,
        subAppId: string,
        roleData: PermissionSubAppRoleData
    ) {
        this.routes.push({
            name: subAppCode,
            path: roleData.path,
            component: noop,
            meta: {
                id: roleData.id,
                isSubApp: true,
                subAppId,
                subAppRootPath: `${subAppCode}/${roleData.path}`,
                subAppCode,
                title: roleData.menuName,
            },
        });
    }
    get menuTree() {
        if (this._menuTree) {
            return this._menuTree.value;
        } else {
            throw new Error(`can't get menuTree before permissions inited`);
        }
    }

    get cacheRoutes() {
        return this._finalCacheRoutes.value;
    }

    get cacheRouteNames() {
        return this._cacheRouteNames.value;
    }

    get activeRoute() {
        return this._activeRoute.value;
    }

    set activeRoute(val) {
        this._activeRoute.value = val;
    }

    addCacheRoute = (routeData: RouteData) => {
        if (!this._enableCache.value) {
            console.warn(`cacheroute did't enable!`);
            return;
        }
        if (
            !this._cacheRoutes.value.some(
                a => a.fullPath === routeData.fullPath
            )
        ) {
            this._cacheRoutes.value.push(routeData);
        }
    };

    closeRoute = (fullPath: string) => {
        if (!this._enableCache.value) {
            console.warn(`cacheroute did't enable!`);
            return;
        }
        let index = this._cacheRoutes.value.findIndex(
            a => a.fullPath === fullPath
        );
        this._cacheRoutes.value.splice(index, 1);
        if (fullPath === this.activeRoute) {
            this.router.replace(this._cacheRoutes.value[index - 1].fullPath);
        }
    };

    closeOtherRoute = (fullPath: string) => {
        if (!this._enableCache.value) {
            console.warn(`cacheroute did't enable!`);
            return;
        }
        this._cacheRoutes.value = this._cacheRoutes.value.filter(
            a => a.fullPath === fullPath || a.path === this._defaultEntiry
        );
        this.router.replace(fullPath);
    };
    closeAllRoute = () => {
        this._cacheRoutes.value = [];
    };

    dispatchChannelEvent = (eventName: string, ...params: any[]) => {
        this.channel.dispatchChannelEvent(eventName, ...params);
    };
    addChannelEvent = (
        eventName: string,
        eventId: string,
        callback: (...params: any[]) => void
    ) => {
        return this.channel.addChannelEvent(eventName, eventId, callback);
    };
    removeChannelEvent = (eventId: string) => {
        this.channel.removeChannelEvent(eventId);
    };
    getChannelEventId = () => {
        return this.channel.getChannelEventId();
    };
    getChannelEventList = (eventId: string) => {
        return this.channel.getChannelEventList(eventId);
    };
    clearChannelEventList = (eventId: string) => {
        this.channel.clearChannelEventList(eventId);
    };
}

const userLazyLoader = (
    asyncView: () => Promise<{ default: RouteComponent }>
) => {
    const component = shallowRef<RouteComponent>();
    const ct = ref(0);
    const status = ref("loading");
    const loadingRef = ref<typeof Loading>();
    const errMsg = ref("");
    const pageLoading = ref(false);
    const setPageLoading = (val: boolean) => {
        pageLoading.value = val;
    };
    const loadComponent = async () => {
        try {
            status.value = "loading";
            const c = await asyncView();
            ct.value++;
            if (c && c.default) {
                status.value = "success";
                component.value = c.default;
            } else {
                status.value = "failed";
            }
        } catch (e: any) {
            if (typeof e.message === "string") {
                if (e.message.startsWith("Loading chunk")) {
                    errMsg.value = "请查看网络访问是否正常";
                } else {
                    errMsg.value = e.message;
                }
            }
            status.value = "failed";
        }
    };
    onMounted(loadComponent);
    const renderContent = () => {
        switch (status.value) {
            case "loading":
                return h(Loading, { ref: loadingRef });
            default:
            case "failed":
                return h(LoadFail, {
                    onRetry: loadComponent,
                    describe: errMsg.value,
                });
        }
    };
    return {
        loadComponent,
        setPageLoading,
        renderContent,
        status,
        loadingRef,
        pageLoading,
        component,
        ct,
    };
};

export const lazyLoader = (
    asyncView: () => Promise<{ default: RouteComponent }>,
    name?: string
) => {
    return defineComponent({
        name,
        setup(props, context) {
            const {
                loadComponent,
                setPageLoading,
                renderContent,
                status,
                loadingRef,
                pageLoading,
                component,
                ct,
            } = userLazyLoader(asyncView);
            context.expose({
                reload: loadComponent,
                setPageLoading,
            });
            return () => {
                if (status.value !== "success") {
                    return h(
                        "div",
                        {
                            style: "width:100%;height:100%;display:flex;align-items:center",
                        },
                        renderContent
                    );
                } else if (component.value) {
                    if (loadingRef.value) {
                        loadingRef.value.clear();
                    }
                    return h(Spin, { spinning: pageLoading.value }, () => [
                        h(component.value!, { key: ct.value }),
                    ]);
                }
            };
        },
    });
};

const Loading = defineComponent({
    props: {
        delay: {
            type: Number,
            default: 1000,
        },
    },
    setup(props, context) {
        const showLoading = ref(false);
        const timeOut = ref();
        const clear = () => {
            if (timeOut.value) {
                clearTimeout(timeOut.value);
                timeOut.value = undefined;
            }
        };
        onMounted(() => {
            timeOut.value = setTimeout(() => {
                showLoading.value = true;
            }, props.delay);
        });
        context.expose({
            clear,
        });
        return () => {
            if (showLoading.value) {
                return h(Spin, { style: "flex:1" }, { tip: "载入中..." });
            } else {
                return null;
            }
        };
    },
});

const LoadFail = defineComponent({
    emits: ["retry"],
    props: {
        describe: String,
    },
    setup(props, context) {
        const retry = () => {
            context.emit("retry");
        };
        return () =>
            h(
                Result,
                {
                    status: "error",
                    title: "页面加载失败",
                    subTitle: props.describe,
                    style: "flex:1",
                },
                {
                    extra: () => h(Button, { onClick: retry }, () => "重试"),
                }
            );
    },
});

export const defaultRouteEntry = "/main/home";
