import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_dicdata: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/dict-data/findDictPage`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/system/dict-data/add`;
    },
    get update() {
        return `${urlManger.getServiceUrl("user")}/system/dict-data/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/system/dict-data/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/dict-data/findDictDataPage`;
    },
};
