import { Metadata } from "@intasect/platform-core";
export const siteMetadata: Metadata[] = [
    {
        fieldName: "siteCode",
        fieldType: "string",
        title: "据点编码",
    },
    {
        fieldName: "siteName",
        fieldType: "string",
        title: "据点名称",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "createTime",
        fieldType: "string",
        title: "创建时间",
    },
    {
        fieldName: "parentId",
        fieldType: "string",
        title: "上级据点",
    },
    {
        fieldName: "siteType",
        fieldType: "string",
        title: "据点类型",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "租户id",
    },
];
