import { Metadata } from "@intasect/platform-core";
export const configurationModelMetadata: Metadata[] = [
    {
        fieldName: "keyId",
        fieldType: "string",
        title: "keyId"
    },
    {
        fieldName: "permissionDesc",
        fieldType: "string",
        title: "模型名称"
    },
    {
        fieldName: "typeName",
        fieldType: "string",
        title: "权限类型"
    },
    {
        fieldName: "params",
        fieldType: "string",
        title: "权限参数"
    },
    {
        fieldName: "addDatetime",
        fieldType: "string",
        title: "创建时间"
    }
];
