import { DictionaryEntity } from "@/entities/dictionary/dictionaryEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_dictionary } from "@/controllers/dictionary/dictionaryUrls";
import { urlManger } from "@/url/common";
export class DictionaryController extends EntityController<DictionaryEntity> {
    constructor(request: IRequest) {
        super(request, url_dictionary, "字典");
    }
    static readonly entityName = "dictionary";
    async getTenantsTree(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async dicAdd(data: any) {
        const res = await this.request({
            action: "add",
            url: url_dictionary.save,
            method: "post",
            data,
            showSuccess: true,
            applyLoading: true,
        });
        return res;
    }
    async dicUpdate(data: any) {
        const res = await this.request({
            action: "add",
            url: url_dictionary.update,
            method: "post",
            data,
            showSuccess: true,
            applyLoading: true,
        });
        return res;
    }
    async dicDelete(data: any) {
        const res = await this.request({
            action: "del",
            url: url_dictionary.delete,
            method: "post",
            data,
            showSuccess: true,
        });
        return res;
    }
    async getDicList(data: any) {
        const res = await this.request({
            action: "",
            url: url_dictionary.page,
            method: "post",
            data,
        });
        return res;
    }
    async copyAndAdd(data: any) {
        const res = await this.request({
            action: "add",
            url: url_dictionary.copy,
            method: "post",
            data,
            showSuccess: true,
        });
        return res;
    }
}
