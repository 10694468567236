import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_department: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/department/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/api/department/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("user")}/api/department/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/api/department/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/dept/getDeptTree`;
    },
    get list() {
        return `${urlManger.getServiceUrl("user")}/system/dept/getDeptTree`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("user")}/api/department/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("user")}/api/department/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("user")}/api/department/exportPdf`;
    },
};
