import { ServerResponse } from "@/login/declare";
import { EntityServiceResponseHandler } from "@intasect/platform-core";

//用于处理和后端返回数据的响应逻辑
export const responseHandler: Record<string, EntityServiceResponseHandler> = {
    default: {
        //获取业务信息
        restfulAPIMessage: (result: ServerResponse<any>) => {
            return result.message || result.header.message;
        },
        //获取业务数据
        dataResolve: (result: ServerResponse<any>) => {
            return result.body;
        },
        //业务响应判断
        check: (result: ServerResponse<any>): boolean => {
            return !!result.success;
        },
    },
};
