import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_subscribe: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/cmb/subscribe/findSubscribePage`;
    },
    get list() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/list`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/subscribe/exportPdf`;
    }
};
