import { ServerResponse } from "@/login/declare";
import {
    CallbackResolveType,
    CallbackType,
    EventDef,
    EventMap,
    EventType,
    IRequest,
    IRequestConfig,
    IService,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";

export type ToDoType = {
    id: string;
    requestConfig: IRequestConfig<ServerResponse<{ total: number }>>;
};

export type ToDoUpdateCallbackType = (id: string, total: number) => void;

const ToDoServiceType = Symbol.for("ToDoService");

export enum ToDoServiceEvents {
    toDoCountUpdated = "toDoCountUpdated",
}

const ToDoServiceEventDefs: EventDef[] = [
    {
        eventName: ToDoServiceEvents.toDoCountUpdated,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["id", "total"],
    },
];

export class ToDoService implements IService<Record<string, ToDoType>> {
    serviceData: Record<string, ToDoType> = {};
    isDev: RefType<boolean>;
    request!: IRequest;
    protected eventMap!: EventMap;

    init = (platform: PlatformCore) => {
        this.request = platform.authRequest;
        this.isDev = platform.isDev;
        this.eventMap = platform.addEventDef(
            "toDoService",
            ToDoServiceEventDefs
        );
    };

    addToDoDef(toDoDef: ToDoType) {
        if (this.serviceData[toDoDef.id]) {
            console.warn(
                this.serviceData[toDoDef.id],
                `todo def has been repalced by `,
                toDoDef
            );
        }
        this.serviceData[toDoDef.id] = toDoDef;
    }

    removeToDoDef(id: string) {
        delete this.serviceData[id];
    }

    addOnToDoUpdate(fn: ToDoUpdateCallbackType) {
        return this.events(ToDoServiceEvents.toDoCountUpdated).addEventListener(
            fn
        );
    }

    removeOnToDoUpdate(listenerId: string) {
        this.events(ToDoServiceEvents.toDoCountUpdated).removeEventListener(
            listenerId
        );
    }

    async updateToDo(id: string, config?: IRequestConfig) {
        if (this.serviceData[id]) {
            const result = await this.request({
                ...this.serviceData[id].requestConfig,
                ...config,
            });
            this.events(ToDoServiceEvents.toDoCountUpdated).dispatch([
                id,
                result,
            ]);
        }
    }

    async updateAll() {
        for (const key in this.serviceData) {
            this.updateToDo(key);
        }
    }

    events(eventsName: ToDoServiceEvents) {
        return this.eventMap[eventsName];
    }

    static readonly iocType = ToDoServiceType;
}
