import { Metadata } from "@intasect/platform-core";
export const subscribeMetadata: Metadata[] = [
    {
        fieldName: "keyId",
        fieldType: "string",
        title: "订阅id"
    },
    {
        fieldName: "eventId",
        fieldType: "string",
        title: "事件id"
    },
    {
        fieldName: "orgId",
        fieldType: "string",
        title: "订阅组织"
    },
    {
        fieldName: "orgName",
        fieldType: "string",
        title: "组织名"
    },
    {
        fieldName: "orgCode",
        fieldType: "string",
        title: "组织编码"
    },
    {
        fieldName: "subDesc",
        fieldType: "string",
        title: "描述"
    },
    {
        fieldName: "enableStatus",
        fieldType: "string",
        title: "状态"
    },
    {
        fieldName: "requestPermissionDesc",
        fieldType: "string",
        title: "请求模型"
    },
    {
        fieldName: "actionUri",
        fieldType: "string",
        title: "调用地址"
    },
];
