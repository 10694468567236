import { Metadata } from "@intasect/platform-core";
export const departmentMetadata: Metadata[] = [
    {
        fieldName: "id",
        fieldType: "string",
        title: "部门ID",
    },
    {
        fieldName: "deptCode",
        fieldType: "string",
        title: "部门编码",
    },
    {
        fieldName: "deptName",
        fieldType: "string",
        title: "部门名称",
    },
    {
        fieldName: "orderNum",
        fieldType: "string",
        title: "排序",
    },
    {
        fieldName: "ancestorNames",
        fieldType: "string",
        title: "祖级列表",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "租户id",
    },
    {
        fieldName: "deptType",
        fieldType: "string",
        title: "部门类型",
    },
];
