import { TenantsEntity } from "@/entities/tenants/tenantsEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_tenants } from "@/controllers/tenants/tenantsUrls";
import { urlManger } from "@/url/common";
export class TenantsController extends EntityController<TenantsEntity> {
    constructor(request: IRequest) {
        super(request, url_tenants, "企业");
    }
    static readonly entityName = "tenants";

    async getTenantPermission(tenantsId: string) {
        return await this.request({
            url: url_tenants.tenantPermission,
            method: "get",
            params: {
                tenantsId,
            },
        });
    }

    async updateTenantPermission(data: any) {
        return await this.request({
            url: url_tenants.updateTenantPermission,
            method: "post",
            data,
            applyLoading: true,
        });
    }

    async saveOrUpdate(data: any, action: string) {
        return await this.request({
            url: url_tenants.save,
            action,
            method: "post",
            data,
            applyLoading: true,
        });
    }

    async deleteItem(id: string) {
        return await this.request({
            url: `${url_tenants.delete}/${id}`,
            method: "delete",
        });
    }
    async selectOauthDetail(params: any) {
        return await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/selectOauthDetail`,
            method: "get",
          
            params

        });
    }
    async updateOauthDetail(data: any) {
        return await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/updateOauthDetail`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"

        });
    }
}
