import { ResourceClass } from "@/service/declare";
import { DemoResource } from "./demoResource";
import { SiteResource } from "./site";
import { getSysTenantsTreeResource } from "./formDefResource";
export { resourceStatic } from "./static";

export const resourceClass: Record<string, ResourceClass> = {
    [DemoResource.resourceCode]: DemoResource,
    [SiteResource.resourceCode]: SiteResource,
    [getSysTenantsTreeResource.resourceCode]: getSysTenantsTreeResource,
};
