import { EmailController } from "@/controllers/email/email/emailController";
import { InviteregisterController } from "@/controllers/inviteregister/inviteregisterController";
import { ScriptController } from "@/controllers/cmb/script/scriptController";
import { ProcessingMessageController } from "@/controllers/cmb/processingMessage/processingMessageController";
import { MessageController } from "@/controllers/cmb/message/messageController";
import { SubscribeController } from "@/controllers/cmb/subscribe/subscribeController";
import { EventController } from "@/controllers/cmb/event/eventController";
import { ConfigurationModelController } from "@/controllers/cmb/configurationModel/configurationModelController";
import { WhitelistController } from "@/controllers/cmb/whitelist/whitelistController";
import { OpreationLogController } from "@/controllers/opreationLog/opreationLogController";
import { ClientController } from "@/controllers/client/clientController";
import { LoginLogController } from "@/controllers/loginLog/loginLogController";
import { DicdataController } from "@/controllers/dicdata/dicdataController";
import { DictionaryController } from "@/controllers/dictionary/dictionaryController";
import { SettingController } from "@/controllers/setting/settingController";
import { SubAppController } from "@/controllers/subApp/subAppController";
import { RoleController } from "@/controllers/role/roleController";
import { UserController } from "@/controllers/user/userController";
import { RoleUserController } from "@/controllers/user/userController";
import { SiteUserController } from "@/controllers/user/userController";
import { PostController } from "@/controllers/post/postController";
import { MenusController } from "@/controllers/menus/menusController";
import { RoleMenusController } from "@/controllers/menus/roleMenusController";
import { PartsController } from "./parts/partsController";
import { TenantsController } from "./tenants/tenantsController";
import { SiteController } from "@/controllers/site/siteController";
import { DepartmentController } from "@/controllers/department/departmentController";
import { EntityControllerDef } from "@intasect/platform-core";
export const Controllers = {
    [EmailController.entityName]: EmailController,
    [InviteregisterController.entityName]: InviteregisterController,
    [ScriptController.entityName]: ScriptController,
    [ProcessingMessageController.entityName]: ProcessingMessageController,
    [MessageController.entityName]: MessageController,
    [SubscribeController.entityName]: SubscribeController,
    [EventController.entityName]: EventController,
    [ConfigurationModelController.entityName]: ConfigurationModelController,
    [WhitelistController.entityName]: WhitelistController,
    [OpreationLogController.entityName]: OpreationLogController,
    [ClientController.entityName]: ClientController,
    [DicdataController.entityName]: DicdataController,
    [LoginLogController.entityName]: LoginLogController,
    [DictionaryController.entityName]: DictionaryController,
    [SettingController.entityName]: SettingController,
    [SubAppController.entityName]: SubAppController,
    [RoleController.entityName]: RoleController,
    [UserController.entityName]: UserController,
    [RoleUserController.entityName]: RoleUserController,
    [SiteUserController.entityName]: SiteUserController,
    [PostController.entityName]: PostController,
    [MenusController.entityName]: MenusController,
    [RoleMenusController.entityName]: RoleMenusController,
    [TenantsController.entityName]: TenantsController,
    [PartsController.entityName]: PartsController,
    [SiteController.entityName]: SiteController,
    [DepartmentController.entityName]: DepartmentController
};
