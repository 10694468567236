import type { Platform } from "@/config/platform";
import { url_setting } from "@/controllers/setting/settingUrls";
import { ServerResponse } from "@/login/declare";
import {
    AuthManagerEvents,
    IService,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import { ref } from "vue";

export type SystemServiceData = {
    baseImage: string;
    systemTitle: string;
};

const SystemServiceType = Symbol.for("SystemService");

export class SystemService implements IService<SystemServiceData> {
    isDev!: RefType<boolean>;
    private platform!: PlatformCore;
    private _serviceData = ref<SystemServiceData>({
        baseImage: "",
        systemTitle: "",
    });

    init = (platform: PlatformCore) => {
        this.platform = platform;
        platform.multiAuthManager.authManager
            .event(AuthManagerEvents.authSuccess)
            .addEventListener(async () => {
                this.updateSystemInfo();
            });
        platform.multiAuthManager.authManager
            .event(AuthManagerEvents.authClear)
            .addEventListener(() => {
                this.clear();
            });
        this.isDev = platform.isDev;
    };
    private clear = () => {
        this._serviceData.value = {
            baseImage: "",
            systemTitle: "",
        };
    };

    updateSystemInfo = async () => {
        const systemInfo = await this.getSystemInfo(this.platform);
        if (systemInfo.success) {
            if (systemInfo.body) {
                this._serviceData.value = systemInfo.body;
                let blob: any =
                    await this.platform.multiAuthManager.authRequest({
                        method: "get",
                        url: url_setting.downloadUrl,
                        params: {
                            fileUrl: systemInfo.body.baseImage,
                            isImagePreview: "0",
                        },
                        responseType: "blob",
                    });
                this._serviceData.value.baseImage = URL.createObjectURL(blob);
                this._serviceData.value.systemTitle =
                    systemInfo.body.systemTitle;
            }
        } else {
            (this.platform as Platform).messageError("获取系统信息失败！");
        }
    };

    private getSystemInfo = async (platform: PlatformCore) => {
        const result = await platform.multiAuthManager.authRequest<
            ServerResponse<SystemServiceData>
        >({
            url: url_setting.getSystemInfo,
            method: "get",
            // params: {
            //     tenantsId: "0",
            // },
        });
        return result;
    };

    get serviceData() {
        return this._serviceData.value;
    }

    get systemTitle() {
        return this._serviceData.value.systemTitle;
    }

    set systemTitle(val) {
        this._serviceData.value.systemTitle = val;
    }

    get logo() {
        return this._serviceData.value.baseImage;
    }

    set logo(val) {
        this._serviceData.value.baseImage = val;
    }

    static readonly iocType = SystemServiceType;
}
