import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_site: EntityUrl<{ findList: string }> = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/site/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/api/site/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/site/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/site/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/site/getSiteTree`;
    },
    get list() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/site/getSiteTree`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/site/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/site/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/site/exportPdf`;
    },
    get findList() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/api/site/findTenantsSiteList`;
    },
};
