import { MenuType } from "@/router/declare";
import { OptionType } from "@/components/i-components/Presenters/declare";
import { IRequest } from "@intasect/platform-core";

export type ActionEntitySet = {
    action: string;
    describe: string;
};

export type UserInfo = {
    id: string;
    name: string;
    userType: string;
};

export type PermissionServiceData = UserInfo & {
    role: {
        permissions: PermissionRoleData[];
        superAdmin: boolean;
    };
};

export type PermissionSubAppServiceData = UserInfo & {
    role: {
        permissions: PermissionSubAppRoleData[];
        superAdmin: boolean;
    };
};

export type PermissionRoleData = {
    parentId: string; //子应用菜单结构 上级为0时 ，是子应用菜单跟节点的权限控制
    component: string; //不需要的字段
    menuType: MenuType; //一个新的菜单类型 子应用
    menuName: string; //菜单名称
    perms: string; //子应用的名称，唯一，不可重复
    orderNum: string; //菜单序号
    icon: string; //菜单对应的图标
    id: string; //自身的permissionId
    path: string; //路由地址
    children?: PermissionRoleData[];
    visible:string;
};

export type PermissionSubAppRoleData = PermissionRoleData & {
    subAppId: string; //子应用的id
    children?: PermissionSubAppRoleData[];
};
export type FileResponse<T = string> = {
    success: boolean;
    data: T;
    message: string;
};

export type ResourceLoaderParams = {
    resourceCode: string;
    parentNode?: OptionType;
    keyword?: string;
};

export type ResourceLoader = (
    options: ResourceLoaderParams,
    ...params: any[]
) => Promise<OptionType[]> | OptionType[];

export interface IResource {
    getResource: ResourceLoader;
}

export abstract class ResourceBase implements IResource {
    constructor(protected request: IRequest) {}
    getResource: ResourceLoader = () => {
        throw new Error(`please override this method`);
    };
}

export type ResourceClass = new (request: IRequest) => IResource;
