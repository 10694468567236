import { Metadata } from "@intasect/platform-core";

export const tenantsMetadata: Metadata[] = [
    {
        fieldName: "tenantsName",
        fieldType: "string",
        title: "企业名称",
    },
    {
        fieldName: "tenantsCode",
        fieldType: "string",
        title: "企业编码",
    },
    {
        fieldName: "tenantsCreditCode",
        fieldType: "string",
        title: "统一社会信用代码",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "tenantsContacts",
        fieldType: "string",
        title: "联系人姓名",
    },
    {
        fieldName: "contactsPhone",
        fieldType: "string",
        title: "联系人电话",
    },
    {
        fieldName: "contactsEmail",
        fieldType: "string",
        title: "联系人邮箱",
    },
    {
        fieldName: "createTime",
        fieldType: "string",
        title: "创建时间",
    },
    {
        fieldName: "tenantsType",
        fieldType: "string",
        title: "企业类型",
    },
    {
        fieldName: "address",
        fieldType: "string",
        title: "企业地址",
    },
    {
        fieldName: "parentId",
        fieldType: "string",
        title: "上级企业",
    },
    {
        fieldName: "accountPrefix",
        fieldType: "string",
        title: "统一账号前缀",
    },
];
