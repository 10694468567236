import { SiteEntity } from "@/entities/site/siteEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_site } from "@/controllers/site/siteUrls";
import { urlManger } from "@/url/common";
export class SiteController extends EntityController<SiteEntity> {
    constructor(request: IRequest) {
        super(request, url_site, "据点");
    }
    static readonly entityName = "site";

    async getTenantsTree(data: { tenantsName?: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async siteDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/site/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async getSiteTree(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/site/getSiteTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async createOrUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/site/save-or-update`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
}
