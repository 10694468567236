import { EmailEntity } from "@/entities/email/email/emailEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_email } from "@/controllers/email/email/emailUrls";
export class EmailController extends EntityController<EmailEntity> {
    constructor(request: IRequest) {
        super(request, url_email, "");
    }
    static readonly entityName = "email";
    
    sendEmail = (data: any) => {
        return this.request({
            url: url_email.sendEmail+data,
            method: "get",
            applyLoading: true,
        });
    };
}
