import { Metadata } from "@intasect/platform-core";

export const menusMetadata: Metadata[] = [
    {
        fieldName: "menuName",
        fieldType: "string",
        title: "菜单名称",
    },
    {
        fieldName: "terminalType",
        fieldType: "string",
        title: "终端类型",
    },
    {
        fieldName: "icon",
        fieldType: "string",
        title: "菜单图标",
    },
    {
        fieldName: "orderNum",
        fieldType: "string",
        title: "排序",
    },
    {
        fieldName: "perms",
        fieldType: "string",
        title: "权限标识",
    },
    {
        fieldName: "path",
        fieldType: "string",
        title: "路由地址",
    },
    {
        fieldName: "component",
        fieldType: "string",
        title: "组件路径",
    },
    {
        fieldName: "menuType",
        fieldType: "string",
        title: "菜单类型",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
];
