import { Metadata } from "@intasect/platform-core";
export const whitelistMetadata: Metadata[] = [
    {
        fieldName: "keyId",
        fieldType: "string",
        title: "id"
    },
    {
        fieldName: "orgId",
        fieldType: "string",
        title: "企业id"
    },
    {
        fieldName: "subOrgId",
        fieldType: "string",
        title: "客户id"
    },
    {
        fieldName: "subOrgCode",
        fieldType: "string",
        title: "客户企业编码"
    },
    {
        fieldName: "subOrgName",
        fieldType: "string",
        title: "客户企业名称"
    },
    {
        fieldName: "enableFlag",
        fieldType: "string",
        title: "状态"
    },
    {
        fieldName: "validityStart",
        fieldType: "string",
        title: "有效期起"
    },
    {
        fieldName: "validityEnd",
        fieldType: "string",
        title: "有效期止"
    },
    {
        fieldName: "remark",
        fieldType: "string",
        title: "备注"
    },
    {
        fieldName: "addDatetime",
        fieldType: "string",
        title: "加入白名单时间"
    }
];
