import { OptionType } from "@/components/i-components/Presenters/declare";
import { url_site } from "@/controllers/site/siteUrls";
import { SiteEntity } from "@/entities/site/siteEntity";
import { ServerResponse } from "@/login/declare";
import { ResourceBase, ResourceLoader } from "@/service/declare";
export class SiteResource extends ResourceBase {
    getResource: ResourceLoader = async (_, tenantID?: string) => {
        const list = await this.request<ServerResponse<SiteEntity[]>>({
            url: url_site.findList,
            method: "get",
            params: {
                tenantID,
            },
        });
        return list.body.map<OptionType>(a => ({
            label: a.siteName,
            value: a.siteCode,
            data: a,
        }));
    };
    static resourceCode = "site";
}
