import { uuid } from "@intasect/platform-core";

export class RouterChannel {
    private _eventIds: string[];
    // eventName id callbak
    private _cacheRouteChannel: Map<
        string,
        Map<string, Array<(...params: any) => Promise<void> | void>>
    >;
    //id eventName params
    private _cacheRouteEventList: Map<string, Map<string, Array<any>>>;
    constructor() {
        this._cacheRouteChannel = new Map();
        this._cacheRouteEventList = new Map();
        this._eventIds = [];
    }

    dispatchChannelEvent = (eventName: string, ...params: any[]) => {
        if (this._cacheRouteChannel.has(eventName)) {
            this._cacheRouteChannel.get(eventName)!.forEach((item, eventId) => {
                this._cacheRouteEventList.get(eventId)!.set(eventName, params);
            });
        }
    };
    addChannelEvent = (
        eventName: string,
        eventId: string,
        callback: (...params: any[]) => void
    ) => {
        if (!this._cacheRouteChannel.has(eventName)) {
            this._cacheRouteChannel.set(eventName, new Map());
        }
        const events = this._cacheRouteChannel.get(eventName);
        if (!events!.has(eventId)) {
            events!.set(eventId, []);
            this._cacheRouteEventList.set(eventId, new Map());
        }
        events!.get(eventId)!.push(callback);
        return events!.get(eventId)!;
    };
    removeChannelEvent = (eventId: string) => {
        this._cacheRouteChannel.forEach(item => {
            item.has(eventId);
            item.delete(eventId);
        });
        this._cacheRouteEventList.delete(eventId);
    };
    getChannelEventId = () => {
        let id = uuid(8, 32);
        while (this._eventIds.includes(id)) {
            id = uuid(8, 32);
        }
        return id;
    };
    getChannelEventList = (eventId: string) => {
        return this._cacheRouteEventList.get(eventId);
    };
    clearChannelEventList = (eventId: string) => {
        this._cacheRouteEventList.get(eventId)?.clear();
    };
}
