import {
    generNetWorkErrorMessage,
    IRequestInst,
    IRequestAdapter,
    IRequestConfig,
    IRequestError,
    IResponse,
    RequestConfig,
    IResponseError,
} from "@intasect/platform-core";
import axios, { AxiosError, AxiosInstance } from "axios";

export class AxiosAdapter implements IRequestAdapter {
    private _inst: IRequestInst;
    private _axiosInst: AxiosInstance;
    constructor(private showError: (message: string, title?: string) => void) {
        this._axiosInst = axios.create();
        const i = async <T = any>(
            config: IRequestConfig<T>
        ): Promise<IResponse<T>> => {
            const { data, headers, ...rest } = await this._axiosInst(config);
            return {
                ...rest,
                data: data as T,
                headers: headers as any,
            };
        };
        this._inst = i as IRequestInst;
    }
    processError: (error: IResponseError) => void = error => {
        if (error.response) {
            this.showError(
                generNetWorkErrorMessage(error.response.status as any),
                "请求错误"
            );
        } else {
            this.showError(error.message, "请求错误");
        }
    };
    requestErrorHandler: (error: IRequestError) => IResponse<any> = error => {
        if (error.config.autoProcessError === false) {
            return {
                config: error.config,
                headers: {},
                status: 0,
                statusText: error.message,
            };
        } else {
            throw {
                config: error.config,
                message: error.message,
            };
        }
    };
    responseErrorHandler: (error: IResponse<any>) => IResponse<any> = error => {
        const { config, ...response } = error as any as AxiosError;
        if ((config as IRequestConfig).autoProcessError === false) {
            return error;
        }
        throw {
            message: generNetWorkErrorMessage(response.status as any),
            response,
            config,
        };
    };

    init(config: RequestConfig): void {
        throw new Error("Method not implemented.");
    }
    addRequestInterceptor<T extends IRequestConfig<any> = IRequestConfig<any>>(
        resolve?: ((config: T) => T | Promise<T>) | undefined,
        reject?: ((error: IRequestError) => any) | undefined
    ): number {
        //@ts-ignore
        return this._axiosInst.interceptors.request.use(resolve, reject);
    }
    removeRequestInterceptor(id: number): void {
        this._axiosInst.interceptors.request.eject(id);
    }
    addResponseInterceptor<T extends IResponse<any> = IResponse<any>>(
        resolve?: ((config: T) => T | Promise<T>) | undefined,
        reject?: ((error: IRequestError) => any) | undefined
    ): number {
        //@ts-ignore
        return this._axiosInst.interceptors.response.use(resolve, reject);
    }
    removeResponseInterceptor(id: number): void {
        this._axiosInst.interceptors.response.eject(id);
    }
    get inst() {
        return this._inst;
    }
    cancelResovle<T extends IRequestConfig<any> = IRequestConfig<any>>(
        config: T
    ): void {
        const controller = new AbortController();
        //@ts-ignore
        config.signal = controller.signal;
        controller.abort();
    }
}
