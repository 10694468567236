// import { Input, InputNumber, Form, Modal } from "@intasect/ant-design-vue";
import type { App } from "vue";
import IModal from "@/components/i-components/IModal/IModal.vue";
import IDate from "@/components/i-components/IDate/IDate";
// import IReferTree from "@/components/i-components/IReferTree/IReferTree";
import IRefer from "@/components/i-components/IRefer/IRefer";
// import IInput from "@/components/i-components/IInput/IInput";
// import { IInputNumber } from "@/components/i-components";
//全局通用组件注册
export const globalComponents = function (app: App) {
    app.component("i-modal", IModal)
        .component("i-date", IDate)
        .component("i-refer", IRefer);
    // app.use(Input)
    //     .use(InputNumber)
    //     .use(Form)
    //     .use(Modal)
    //     .component("i-modal", IModal)
    //     .component("i-refer-tree", IReferTree)
    //     .component("i-refer", IRefer)
    //     .component("i-input", IInput)
    //     .component("i-input-number", IInputNumber);
};
