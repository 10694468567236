import { Platform } from "@/config/platform";
import { QianKunApp } from "@/microApp/declare";
import { MicroAppRegister } from "@/microApp/MicroAppRegister";
import { MenuDef, MenuType } from "@/router/declare";
import { RouterManager } from "@/router/RouterManager";
import { urlManger } from "@/url/common";
import {
    IGlobalStore,
    IService,
    MicroAppManager,
    MicroAppPlatformCore,
    MultiAuthManager,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import {
    SocketInst,
    SocketManager,
} from "@intasect/platform-core/es/managers/socket/SocketManager";
import {
    PermissionSubAppRoleData,
    PermissionSubAppServiceData,
} from "./declare";
import { PermissionService } from "./permission";

const DeveloperServiceType = Symbol.for("DeveloperService");
export type DeveloperServiceData = Record<string, unknown>;

export type AppConfig = Partial<{
    ServerIP: string;
    appName: string;
    appType: "Default" | "MainApp" | "SubApp";
    devMode: boolean;
    publicPath: string;
    routeCache?: boolean;
    langs?: string[];
    defaultLang?: string;
}>;

export type DevConfig = {
    id?: string;
    subApps?: Array<SubAppMockType>;
    subAppRoutes?: Array<SubAppRoutesMockType>;
    port: string;
};

export type SubAppMockType = QianKunApp & {
    routes: Array<{ path: string; title: string; component?: string }>;
};

export type SubAppRoutesMockType = Omit<
    MenuDef,
    "type" | "code" | "icon" | "children"
> & { subAppId: string; subAppCode: string; path: string; component?: string };

export type SDKSocketDataType<T = any> = {
    action: string;
    data: T;
    autoRefresh?: boolean;
};
export class DeveloperService implements IService<DeveloperServiceData> {
    private _serviceData!: DeveloperServiceData;
    public isDev!: RefType<boolean>;
    private routerManager!: RouterManager;
    private permissionService!: PermissionService;
    private multiAuthManager!: MultiAuthManager;
    private microAppRegister!: MicroAppRegister;
    private microAppManager!: MicroAppManager<PermissionSubAppServiceData>;
    private socketManager!: SocketManager;
    private sdkPort = "8139";
    public devConfig!: DevConfig;
    private globalStoreManager!: IGlobalStore;
    private sdkVersion = "1.0.3";
    private appConfig: AppConfig;
    devSocket!: SocketInst;

    get serviceData() {
        return this._serviceData;
    }

    init = (platform: PlatformCore) => {
        this.isDev = platform.isDev;
        this.appConfig = (platform as Platform).appConfig;
        this.routerManager = (platform as Platform).routerManager;
        this.multiAuthManager = platform.multiAuthManager;
        this.permissionService = platform.getService(PermissionService);
        //@ts-ignore
        this.microAppRegister =
            //@ts-ignore
            (
                platform as MicroAppPlatformCore<PermissionSubAppServiceData>
            ).microAppRegister;
        this.microAppManager = (
            platform as MicroAppPlatformCore<PermissionSubAppServiceData>
        ).microAppManager;
        this.globalStoreManager = platform.globalStoreManager;
        this.socketManager = platform.socketManager;
    };

    enable() {
        this.isDev.value = true;
    }

    disable() {
        this.isDev.value = false;
    }

    private modeCheck() {
        if (!this.isDev.value) {
            throw new Error("only can be used in devMode"!);
        }
    }

    addMenu(appDefs: QianKunApp[]) {
        // throw new Error('此功能尚未实现！')
        // this.modeCheck();
        //@ts-ignore
    }

    addSubApps(apps: SubAppMockType[]) {
        apps.forEach(app => {
            const roleDatas: PermissionSubAppRoleData[] = [];
            app.routes.forEach((ar, index) => {
                const roleData = this.addSubAppMenuDef({
                    id: `route-${app.name}-${index}`,
                    parentId: app.name,
                    order: index,
                    subAppCode: app.name,
                    subAppId: `subAppId-${app.name}`,
                    title: ar.title,
                    path: ar.path,
                    component: ar.component,
                    visible: "0",
                });
                roleDatas.push(roleData);
                this.routerManager.addMicroAppRoute(
                    app.name,
                    `subAppId-${app.name}`,
                    roleData
                );
            });
            this.permissionService.addPermissions(...roleDatas);
            this.addSubAppDef(app);
        });
    }

    addSubAppMenuDef(menuDef: SubAppRoutesMockType) {
        this.modeCheck();
        const roleData: PermissionSubAppRoleData = {
            id: menuDef.id,
            parentId: menuDef.parentId,
            menuType: MenuType.subApp,
            component: menuDef.component || "",
            menuName: menuDef.title,
            path: menuDef.path,
            icon: "home",
            perms: `fakePerms-${menuDef.id}`,
            orderNum: menuDef.order.toString(),
            subAppId: menuDef.subAppId,
            visible: "0",
        };
        return roleData;
    }

    addSubAppDef(def: QianKunApp) {
        this.microAppManager.addSubAppDef([
            {
                id: `subAppId-${def.name}`,
                subAppCode: def.name,
                subAppName: def.title,
                subAppRoot: def.entry,
            },
        ]);
        this.permissionService.addPermissions({
            id: def.name,
            parentId: "0",
            menuType: MenuType.list,
            component: "",
            menuName: def.title,
            path: def.name,
            icon: "home",
            perms: `fakePerms-${def.name}`,
            orderNum: "99",
            subAppId: `subAppId-${def.name}`,
            visible: "0",
        });
        //@ts-ignore
        this.microAppRegister.addSubApps([def], this.microAppManager.events);
    }

    noServerLogin() {
        this.modeCheck();
        this.permissionService.useMockPermission = true;
        this.permissionService.getModulePermission = async () => ({
            success: true,
            data: [],
        });
        this.multiAuthManager.useIgoreAuth();
        this.routerManager.router.push("/");
    }

    serverLogin(authKey = "pc") {
        this.modeCheck();
        this.multiAuthManager.currentAuthKey = authKey;
        this.permissionService.useMockPermission = false;
    }

    getPermission() {
        this.devSocket.send(
            JSON.stringify({
                action: "permission",
                id: this.devConfig.id,
                data: this.permissionService.serviceData,
            })
        );
    }

    getSubAppDef() {
        this.devSocket.send(
            JSON.stringify({
                action: "subApp",
                id: this.devConfig.id,
                data: this.permissionService.subAppDefs,
            })
        );
    }

    get UrlManager() {
        return urlManger;
    }

    async connectToSDK() {
        const configData = await this.loadConfig();
        this.devSocket = await this.socketManager.createSocket(
            `localhost:${configData.devConfig?.port || this.sdkPort}`
        );
        this.devSocket.onMessage((result: SDKSocketDataType | undefined) => {
            if (result) {
                switch (result.action) {
                    case "ready":
                        this.devSocket.send(
                            JSON.stringify({
                                action: "webAppConfig",
                                data: configData,
                            })
                        );
                        break;
                    case "setDevConfig":
                        this.globalStoreManager.setKeyCache(
                            "devConfig",
                            JSON.stringify(result.data)
                        );
                        console.warn("已更新devConfig，需要刷新页面才能生效！");
                        break;
                    case "getPermission":
                        this.getPermission();
                        break;
                    case "getSubApp":
                        this.getSubAppDef();
                        break;
                }
            }
        });
    }

    private async loadConfig() {
        return {
            devConfig: this.devConfig,
            appConfig: this.appConfig,
            devPath: WEBPACK_GLOBAL.devPath,
            sdkVersion: this.sdkVersion,
        };
    }

    static readonly iocType = DeveloperServiceType;
}
/*
addSubAppMenuDef({
    id:'fdad',
    parentId:'1475292946750169090',
    type:'S',
    order:4,
    title:'production',
    path:'basic-ppm/production-line',
    subAppId:'1473929997339021313',
    subAppCode:'jit-mrp',
    component:'basic/production-line'
})
*/

/*
addSubAppDef([{
    name:'istc-sub-app',
    entry:'http://localhost:8082/',
    title:'子应用测试'
}])
*/

/*
addSubAppMenuDef({
    id:'abcde',
    parentId:'istc-sub-app',
    type:'S',
    order:3,
    title:'istc-sub-app-title',
    path:'/',
    subAppId:'subAppId-istc-sub-app',
    subAppCode:'istc-sub-app',
})
*/

/*
        this.addSubAppDef([{
            name: 'istc-sub-app',
            entry: 'http://localhost:8082/',
            title: '子应用测试',
            routes: [{
                path: '',
                title: '首页'
            }, {
                path: 'about',
                title: 'about'
            }]
        }])
*/

// const devConfig: DevConfig = {
//     subApps: [{
//         name: 'istc-sub-app',
//         entry: 'http://localhost:8082/',
//         title: '子应用测试',
//         routes: [{
//             path: '',
//             title: '首页'
//         }, {
//             path: 'about',
//             title: 'about'
//         },
//         {
//             path: 'parts',
//             title: 'parts'
//         }]
//     }]
// }
