import { ConfigurationModelEntity } from "@/entities/cmb/configurationModel/configurationModelEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_configurationModel } from "@/controllers/cmb/configurationModel/configurationModelUrls";
import { urlManger } from "@/url/common";
export class ConfigurationModelController extends EntityController<ConfigurationModelEntity> {
    constructor(request: IRequest) {
        super(request, url_configurationModel, "请求模型配置");
    }
    static readonly entityName = "configurationModel";
    async permissionList(tenantsId:string) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/request-permission/all/`+tenantsId,
            method: "get",
        });
        return res;
    }
    async save(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/request-permission/add`,
            method: "post",
            data,
            showSuccess: true,
            action: "_default",
        });
        return res;
    }
    async deletepermission(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/request-permission/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async paramsUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/request-permission/params/update`,
            method: "post",
            data,
            showSuccess: true,
            action: "_default",
        });
        return res;
    }
    async getTenantsTree(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
}
