import { ActionEntitySet } from "@/service/declare";
import { RouteRecordRaw } from "vue-router";

export type VueRouteData<T = MenuMeta> = RouteRecordRaw & {
    meta: T;
};

export type MenuMeta = {
    title: string;
    actions?: ActionEntitySet[];
    // order: number,
    // icon: string,
    id: string;
    // parent: string,
    permissionId?: string;
    isSubApp?: boolean;
    subAppId?: string;
    subAppCode?: string;
    subAppRootPath?: string;
    subAppPath?: string;
};

export enum MenuType {
    //按钮
    button = "F",
    //菜单
    menu = "C",
    //目录
    list = "M",
    //子应用
    subApp = "S",
}

export type MenuDef = {
    id: string;
    type: MenuType;
    parentId: string;
    icon: string;
    order: number;
    title: string;
    children?: MenuDef[];
    path?: string;
    isSubApp?: boolean;
    subAppId?: string;
    parent?: MenuDef;
    visible: string;
};

export type RouteData = {
    name: string;
    path: string;
    fullPath: string;
    isSubApp: boolean;
    title: string;
};
