import { App as VueApp, createApp } from "vue";
import App from "./App.vue";
import "normalize.css";
import { Platform } from "./config/platform";
import { globalComponents } from "./config/globalUse";
import { urlManger } from "./url/common";
import { AppConfig } from "./service/developer";
import { autoZoom } from "./utils/pixelRatio";
import axios from "axios";

window.addEventListener("error", function onError(e) {
    // Ignore ResizeObserver error
    if (e.message === "ResizeObserver loop limit exceeded") {
        e.stopPropagation();
        e.stopImmediatePropagation();
    }
});

autoZoom();

let app: VueApp | undefined;
let config: AppConfig = {};
let platform: Platform;

const loadConfig = () => {
    axios
        .get<AppConfig>(WEBPACK_GLOBAL.BASE_URL + WEBPACK_GLOBAL.CONFIG_URL)
        .then(r => {
            __webpack_public_path__ =
                r.data.publicPath +
                    ((r.data.publicPath + "").endsWith("/") ? "" : "/") || "/";
            if (r.data.ServerIP) {
                urlManger.ServerIP = r.data.ServerIP;
            }
            config = r.data;
        })
        .finally(() => {
            app = createApp(App);
            platform = new Platform(app, config);
            app.use(platform);
            globalComponents(app);
            app.mount("#app");
            if (process.env.NODE_ENV === "development") {
                if (platform.appMode === "MainApp") {
                    //@ts-ignore
                    window.iscPlatform = platform;
                }
            }
        });
};

loadConfig();
