import { RouterManager } from "@/router/RouterManager";
import { App, reactive, watch, computed, ref } from "vue";
import { staticRoutes, staticMenus, commonRoutes } from "@/router/staticRoutes";
import {
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
} from "vue-router";
import { MicroAppRegister } from "@/microApp/MicroAppRegister";
import { AppConfig } from "@/service/developer";
import { subAppPathPrefix } from "./subApp";
import { mainAppServiceList } from "@/service";
import { PermissionSubAppRoleData } from "@/service/declare";
import {
    AuthManagerEvents,
    IObserver,
    PlatformContext,
} from "@intasect/platform-core";
import { WebLoginManager } from "@/login/WebLoginManager";
import { BusinessPlatform } from "./businessPlatform";
import { FontFaceRecource } from "./declare";
import { StoreManager } from "@/store/StoreManager";
import { responseHandler } from "./response";
import { assign, isEmpty } from "lodash-es";

const fnList = [
    "append",
    "appendChild",
    "removeChild",
    "insertBefore",
    "replaceChild",
    "replaceChildren",
    "getElementById",
    "getElementsByClassName",
    "getElementsByTagName",
    "querySelectorAll",
];

export class Platform<
    T = any
> extends BusinessPlatform<PermissionSubAppRoleData> {
    public appConfig: AppConfig;
    private activeSubApp = "";
    public showJumper = (
        subAppCode: string,
        params: {
            jumperType: string;
            jumperProps: Record<string, any>;
        }
    ): void => {
        throw new Error(`only can be use when subAppJumper mounted!`);
    };
    public hideJumper = (subAppCode: string): void => {
        throw new Error(`only can be use when subAppJumper mounted!`);
    };

    constructor(vue: App, config: AppConfig) {
        super(
            vue,
            "MainApp",
            {
                storeOptions: {},
                routerOptions: {
                    history: createWebHistory(WEBPACK_GLOBAL.BASE_URL),
                    routes: commonRoutes,
                },
                appName: config.appName || "精益超链协同平台",
                devMode: !!config.devMode,
                responseHandler,
            },
            mainAppServiceList
        );
        this.routerManager = new RouterManager(
            vue,
            "main",
            staticRoutes,
            staticMenus,
            this.config.routerOptions,
            this.platformConfig.routeCache !== false
        );
        this.microAppRegister = new MicroAppRegister(this);
        this.globalStoreManager = new StoreManager(vue, {});
        this.appConfig = config;
        this.init();
        this.addEvents();
        this.multiAuthManager.setAuth(
            "pc",
            new WebLoginManager(this.globalStoreManager)
        );
        this.install = this.install.bind(this);
        this.routerGuad = this.routerGuad.bind(this);
        this.documentEnhance();
        this.routerManager.buildStaticRoutes();
        watch(
            () => this.appName.value,
            () => {
                const titleList = document.title.split(" - ");
                this.setTitle(titleList[1]);
            }
        );
        this.initLocale();
    }

    setTitle = (title: string) => {
        document.title = `${this.appName.value}${title ? " - " + title : ""}`;
    };

    private addEvents() {
        this.addAuthEvents();
        this.addMicroAppEvents();
        this.addPermissionEvents();
        this.addMessageCenterEvents();
        this.addAttachmentsEvents();
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authClear)
            .addEventListener(() => {
                this.routerManager.closeAllRoute();
            });
    }

    async routerGuad(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) {
        if (!this.multiAuthManager.isLogin) {
            //增加注册放行
            if (to.path === "/register" || to.path === "/forgetpassword" || to.path === "/invite") {
                next();
                return;
            }
            if (to.path !== "/login") {
                this.retryPath = to.path;
                if (this.multiAuthManager.hasLogin) {
                    next(false);
                    this.multiAuthManager.autoLoginRetry();
                } else {
                    next("/login");
                }
            } else {
                next();
            }
            return;
        } else if (!this.routeReady.value || !this.subAppReady.value) {
            if (to.path !== "/loading") {
                if (to.path !== "/login") {
                    this.globalStoreManager.setKey("loadingPath", to.fullPath);
                }
                next("/loading");
            } else {
                next();
            }
            return;
        }

        if (to.path.startsWith("/main/") && to.matched.length > 0) {
            this.activeSubApp = "";
        }
        if (to.path.startsWith(subAppPathPrefix)) {
            const list = to.path.split("/");
            if (list.length > 1) {
                const path = to.path + "/";
                const currentRoute = this.routerManager.routes.find(
                    a =>
                        a.name === list[2] &&
                        path.startsWith(
                            subAppPathPrefix + a.meta.subAppRootPath + "/"
                        )
                );
                if (currentRoute) {
                    currentRoute.meta.subAppPath = to.path;
                    //@ts-ignore
                    // this.addCachedRoute(currentRoute);
                    this.routerManager.addCacheRoute({
                        path: to.path,
                        fullPath: to.fullPath,
                        title: currentRoute.meta.title,
                        name: currentRoute.name as string,
                        isSubApp: true,
                    });
                    this.routerManager.activeRoute = to.fullPath;
                    // this.globalStoreManager.setKey("activeRoute", to.path);
                    this.activeSubApp = list[2];
                    next();
                    return;
                } else if (list[3] !== "loading") {
                    console.error("cannot find the route:apps/" + list[2]);
                    next("/main/404");
                    return;
                } else {
                    next();
                    return;
                }
            } else {
                console.error("cannot find the route:" + to.path);
                next("/home/404");
                return;
            }
        }

        if (to.matched.length === 0) {
            next("/main/404");
            return;
        } else if (to.path !== "/login" && to.path !== "/main") {
            this.routerManager.addCacheRoute({
                path: to.path,
                fullPath: to.fullPath,
                isSubApp: !!to.meta.isSubApp,
                title: to.meta.title as string,
                name: to.name as string,
            });
            this.routerManager.activeRoute = to.fullPath;
        }
        next();
    }

    //vue router3适配，否则会导致vue router 4的数据丢失
    vueRouter3Fix = (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
    ) => {
        if (isEmpty(history.state.current)) {
            assign(history.state, { current: from.fullPath });
        }
        next();
    };

    install = (app: App) => {
        const t = this;
        this.routerManager.router.beforeEach(t.vueRouter3Fix);
        this.routerManager.router.beforeEach(t.routerGuad);
        this.routerManager.router.afterEach(to => {
            // if (this.appMode === "SubApp") {
            //     const state = {
            //         ...history.state,
            //         current: to.fullPath,
            //     };
            //     history.replaceState(state, "", window.location.href);
            // }
            if (to.matched.length > 0) {
                this.setTitle(to.meta.title as string);
            }
        });
        app.config.globalProperties.$platform = t;
        app.provide("platform", t);
    };

    loadRootIconFont(content: string[]) {
        const svg = document.createElement("div");
        svg.style.display = "none";
        svg.id = "appSvg";
        let html = "";
        content.forEach(c => {
            html += c;
        });
        svg.innerHTML = `<svg>${html}</svg>`;
        //@ts-ignore
        const app = document.ori_getElementById("app");
        app!.parentNode!.insertBefore(svg, app);
    }

    loadFontFaceList(list: FontFaceRecource[]) {
        list.forEach(l => {
            this.loadFontFace(l);
        });
    }

    documentEnhance() {
        this.subAppDomFn(document);
        this.subAppDomFn(document.body);
    }

    private subAppDomFn(element: Document | HTMLElement) {
        if (element) {
            const t = this;
            const oriQuerySelector = element.querySelector;
            element.querySelector = function <
                K extends keyof HTMLElementTagNameMap
            >(selectors: K) {
                const subResult = t.currentSubAppContainer
                    ? t.currentSubAppContainer.querySelector(selectors)
                    : null;
                const bodyResult = oriQuerySelector.call(element, selectors);
                return subResult || bodyResult;
            };
            this.enhanceElementFn(element);
            //@ts-ignore
            element.ori_QuerySelector = oriQuerySelector;
        }
    }

    private enhanceElementFn(element: Document | HTMLElement) {
        const t = this;
        fnList.forEach(f => {
            //@ts-ignore
            if (typeof element[f] === "function") {
                //@ts-ignore
                const oriFn = element[f];
                //@ts-ignore
                element[f] = function (...params: any[]) {
                    try {
                        if (t.activeSubApp && t.subAppReady.value) {
                            //@ts-ignore
                            return t.currentSubAppContainer[f](...params);
                        } else {
                            return oriFn.call(element, ...params);
                        }
                    } catch (e) {
                        return oriFn.call(element, ...params);
                    }
                };
                //@ts-ignore
                element[`ori_${f}`] = oriFn;
            }
        });
    }

    async loadFontFace({ family, source }: FontFaceRecource) {
        //@ts-ignore
        const f = new FontFace(family, source);
        const result = await f.load();
        if (result.status === "loaded") {
            //@ts-ignore
            document.fonts.add(f);
        } else {
            this.messageWarn(`字体加载失败：${family}-${source}`);
        }
    }

    get currentSubAppContainer() {
        return this.microAppManager.getSubAppContainer(this.activeSubApp);
    }

    get context(): PlatformContext {
        return {};
    }
}

export const ObserverAPI: IObserver = {
    //@ts-ignore
    reactive,
    watch,
    computed,
    ref,
};
