import { defaultRouteEntry, RouterManager } from "@/router/RouterManager";
import { App, reactive, watch, computed, ref } from "vue";
import { StoreOptions } from "vuex";
import { notification } from "@intasect/ant-design-vue";
import {
    ActionTypes,
    BusinessPlatformConfig,
    DictDataDef,
    DictTypeDef,
} from "./declare";
import { RouterOptions } from "vue-router";
import {
    AppMode,
    AuthManagerEvents,
    EntityControllerDef,
    IRequestConfig,
    IResponse,
    MessageEvents,
    MessageInfo,
    MessageLevel,
    MessageType,
    MicroAppCustomProps,
    MicroAppManagerEvents,
    MicroAppPlatformCore,
    PermissionManagerEvents,
    PlatformCoreOptions,
    ServiceList,
    SubAppDef,
    useEntity,
    IObserver,
} from "@intasect/platform-core";
import { DevConfig, DeveloperService } from "@/service/developer";
import { MetadataMap } from "@/entities";
import { Controllers } from "@/controllers";
import { UserService } from "@/service/user";
import { dictTypeApi } from "@/url/dic";
import { url_attachment } from "@/url/platform";
import {
    AttachmentService,
    AttachmentServiceEvents,
} from "@/service/attachment";
import { ResourceService } from "@/service/resource";
import { PermissionSubAppRoleData, ResourceLoader } from "@/service/declare";
import { SystemService } from "@/service/system";
import {
    PermissionService,
    PermissionServiceEvents,
} from "@/service/permission";
import { AxiosAdapter } from "./adapter";
import { ServerResponse } from "@/login/declare";
import { LoadedApp } from "@/microApp/declare";
import { MenuType, RouteData } from "@/router/declare";
import { antdLoader, dayJSLoader } from "@/locales/loader";
import { LocaleService } from "@/service/locale";
import { OptionType } from "@/components/i-components/Presenters/declare";

export interface PlatformVueConfig<T> extends PlatformCoreOptions {
    routerOptions?: RouterOptions;
    storeOptions?: StoreOptions<T>;
    controllers: EntityControllerDef;
    appName?: string;
    devMode?: boolean;
    logo?: string;
}

export class BusinessPlatform<T = any> extends MicroAppPlatformCore<T> {
    public routerManager: RouterManager;
    public readonly routeReady = ref(false);
    public readonly subAppReady = ref(false);
    public readonly globalLoading = ref(false);
    protected retryPath = "";
    protected authIntervalId: number | undefined;
    public pageRefresh = (route: RouteData): void => {
        throw new Error(`plase override this method`);
    };
    public setPageLoading = (val: boolean): void => {
        throw new Error(`plase override this method`);
    };
    public readonly localePath = WEBPACK_GLOBAL.LOCALE_URL;
    constructor(
        protected vue: App,
        appMode: AppMode,
        config: BusinessPlatformConfig<T>,
        serviceList: ServiceList
    ) {
        super(
            {
                ...config,
                MultiAuthManager: {},
                appName: config.appName || "精益超链协同平台",
                PermissionManager: {},
                AuthManager: {
                    adapter: () => new AxiosAdapter(this.messageError),
                },
                MicroAppManager: {},
                controllers: Controllers,
                observerAPI: {
                    //@ts-ignore
                    reactive,
                    watch,
                    computed,
                    ref,
                },
            },
            serviceList,
            {},
            appMode,
            !!config.devMode
        );
    }

    protected addMessageCenterEvents = () => {
        this.messageCenter
            .event(MessageEvents.newMessage)
            .addEventListener((result: MessageInfo) => {
                switch (result.level) {
                    case MessageLevel.success:
                        notification.success({
                            message: result.title || "操作成功",
                            description: result.message,
                        });
                        break;
                    case MessageLevel.info:
                        notification.info({
                            message: result.title || "系统提示",
                            description: result.message,
                        });
                        break;
                    case MessageLevel.warnning:
                        notification.warning({
                            message: result.title || "系统警告",
                            description: result.message,
                        });
                        break;
                    case MessageLevel.error:
                        notification.error({
                            message: result.title || "系统错误",
                            description: result.message,
                        });
                        break;
                    case MessageLevel.exception:
                        notification.error({
                            message: result.title || "系统异常",
                            description: result.message,
                        });
                        break;
                }
            });
    };

    protected addPermissionEvents = () => {
        this.permissionManager
            .event(PermissionManagerEvents.getModulePermissionSuccess)
            .addEventListener((permissions: PermissionSubAppRoleData[]) => {
                this.routerManager.genMenuTree(permissions);
                this.routerManager.buildDynamicRoutes(permissions);
                const defaultEntry = this.routerManager.router
                    .getRoutes()
                    .find(a => a.path === defaultRouteEntry);
                if (
                    defaultEntry &&
                    !this.routerManager.cacheRoutes.some(
                        a => a.fullPath === defaultEntry.path
                    )
                ) {
                    this.routerManager.addCacheRoute({
                        path: defaultEntry.path,
                        fullPath: defaultEntry.path,
                        isSubApp: false,
                        title: "主页",
                        name: defaultEntry.name as string,
                    });
                }
                this.routeReady.value = true;
            });

        this.permissionManager
            .event(PermissionManagerEvents.permissionReady)
            .addEventListener(async () => {
                if (this.platformConfig.devMode) {
                    console.warn(
                        "本地调试模式已启动，可以通过配置localStorage中的devConfig字段配置开发模式。"
                    );
                    try {
                        const localDevConfig = JSON.parse(
                            this.globalStoreManager.getKeyCache("devConfig") ||
                                "{}"
                        );
                        if (localDevConfig) {
                            this.loadDevConfig(localDevConfig);
                        }
                        await this.devTools.connectToSDK();
                        this.devTools.getPermission();
                    } catch (e) {
                        console.log(e);
                    }
                }
            });

        this.getService(PermissionService)
            .events(PermissionServiceEvents.permissionChanged)
            .addEventListener((permissions: PermissionSubAppRoleData[]) => {
                this.routerManager.genMenuTree(permissions);
                this.routerManager.buildDynamicRoutes(permissions);
            });
    };

    protected addAuthEvents = () => {
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authClear)
            .addEventListener(() => {
                this.routerManager.router.replace("/login");
                this.globalStoreManager.clear();
                this.routeReady.value = false;
                clearInterval(this.authIntervalId);
                this.authIntervalId = undefined;
            });

        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authSuccess)
            .addEventListener(() => {
                this.permissionManager.getPermission(
                    this.multiAuthManager.authRequest
                );
                if (this.authIntervalId === undefined) {
                    //@ts-ignore
                    this.authIntervalId = setInterval(
                        () =>
                            this.multiAuthManager.currentAuth.auth(
                                this.noAuthRequest
                            ),
                        (this.multiAuthManager.currentAuth.expir - 1) * 1000
                    );
                }
            });
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.beforeLoading)
            .addEventListener(() => {
                this.globalLoading.value = true;
            });
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.afterLoading)
            .addEventListener(() => {
                this.globalLoading.value = false;
            });
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authRetryFailed)
            .addEventListener((params: { result: string }) => {
                if (params.result !== "has no login log") {
                    this.messageError("系统验证失效，返回到登录页面。");
                }
                this.routerManager.router.replace("/login");
                this.globalLoading.value = false;
            });
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authRetry)
            .addEventListener((params: { type: string }) => {
                this.messageInfo("系统验证失效，正在重新获取验证信息...");
                if (params.type === "autoLoginRetry") {
                    this.globalLoading.value = true;
                }
            });
        this.multiAuthManager.authManager
            .event(AuthManagerEvents.authRetrySuccess)
            .addEventListener((params: { type: string }) => {
                if (params.type === "autoLoginRetry") {
                    this.globalLoading.value = false;
                    this.routerManager.router.push(this.retryPath);
                    this.retryPath = "";
                }
            });
    };

    protected addAttachmentsEvents = () => {
        this.getService(AttachmentService)
            .events(AttachmentServiceEvents.downLoadFail)
            .addEventListener((message: string) => {
                this.messageError(message, "文件下载失败");
            });
        this.getService(AttachmentService)
            .events(AttachmentServiceEvents.acceptFail)
            .addEventListener((message: string) => {
                this.messageError("请上传有效的文件格式！", "文件上传失败");
            });
    };

    protected addMicroAppEvents = () => {
        this.microAppManager
            .event(MicroAppManagerEvents.loadMicroAppSuccess)
            .addEventListener((result: SubAppDef[]) => {
                this.routerManager.buildMicroAppRoutes(
                    result,
                    this.getService(PermissionService).serviceData.filter(
                        a => a.menuType === MenuType.subApp
                    )
                );
                this.subAppReady.value = true;
            });
        this.microAppManager
            .event(MicroAppManagerEvents.afterUnmount)
            .addEventListener(
                (
                    app: LoadedApp<
                        MicroAppCustomProps<PermissionSubAppRoleData>
                    >
                ) => {
                    this.microAppManager.removeSubAppContainer(app.name);
                }
            );
    };

    message = (params: {
        title: string;
        message: string;
        type: MessageType;
        level: MessageLevel;
    }) => {
        const {
            title = "",
            message = "",
            type = MessageType.restfulAPI,
            level = MessageLevel.success,
        } = params;
        this.messageCenter.getMessage({
            title,
            message,
            type,
            level,
        });
    };
    messageSuccess = (message: string, title = "") => {
        this.message({
            message,
            title,
            type: MessageType.restfulAPI,
            level: MessageLevel.success,
        });
    };
    messageInfo = (message: string, title = "") => {
        this.message({
            message,
            title,
            type: MessageType.restfulAPI,
            level: MessageLevel.info,
        });
    };
    messageError = (message: string, title = "") => {
        this.message({
            message,
            title,
            type: MessageType.restfulAPI,
            level: MessageLevel.error,
        });
    };
    messageWarn = (message: string, title = "") => {
        this.message({
            message,
            title,
            type: MessageType.restfulAPI,
            level: MessageLevel.warnning,
        });
    };

    // async routerGuad(
    //     to: RouteLocationNormalized,
    //     from: RouteLocationNormalized,
    //     next: NavigationGuardNext
    // ) {
    //     if (!this.multiAuthManager.isLogin) {
    //         if (to.path !== "/login") {
    //             this.retryPath = to.path;
    //             if (this.multiAuthManager.hasLogin) {
    //                 next(false);
    //                 this.multiAuthManager.autoLoginRetry();
    //             } else {
    //                 next("/login");
    //             }
    //         } else {
    //             next();
    //         }
    //         return;
    //     } else if (!this.routeReady.value) {
    //         if (to.path !== "/loading") {
    //             if (to.path !== "/login") {
    //                 this.globalStoreManager.setKey("loadingPath", to.fullPath);
    //             }
    //             next("/loading");
    //         } else {
    //             next();
    //         }
    //         return;
    //     }
    //     if (to.matched.length === 0) {
    //         next("/main/404");
    //         return;
    //     } else if (to.path !== "/login" && to.path !== "/main") {
    //         this.routerManager.addCacheRoute({
    //             path: to.path,
    //             fullPath: to.fullPath,
    //             isSubApp: !!to.meta.isSubApp,
    //             title: to.meta.title as string,
    //             name: to.name as string,
    //         });
    //         this.routerManager.activeRoute = to.fullPath;
    //     }
    //     next();
    //     return;
    // }

    public readonly appName = computed(() => {
        return (
            this.getService(SystemService).systemTitle ||
            this.platformConfig.appName ||
            "信息管理系统"
        );
    });

    public readonly logo = computed(() => {
        return (
            this.getService(SystemService).logo ||
            this.platformConfig.logo ||
            ""
        );
    });

    getDictList = async (
        dictType: string,
        tenantsId: string | undefined,
        dictCode: string = ""
    ) => {
        const userService = this.getService(UserService);
        const result = await this.authRequest<ServerResponse<DictTypeDef[]>>({
            url: dictTypeApi.findSysDictType,
            method: "post",
            data: {
                dictCode: dictType,
                dictDataCode: dictCode,
                tenantsId: tenantsId ? tenantsId : userService.tenantID,
            },
        });
        if (result.success && result.body.length > 0) {
            return result.body[0].sysDictDatas;
        } else {
            this.messageCenter.getMessage({
                message: "获取字典信息失败！",
                title: "请求错误",
                type: MessageType.query,
                level: MessageLevel.error,
            });
            return [];
        }
    };

    private dicCache = new Map<
        string,
        {
            loading: boolean;
            completed: boolean;
            data: DictDataDef[];
            queue: Array<(data: DictDataDef[]) => void>;
        }
    >();

    getDicCache = async (
        dictType: string,
        tenantsId?: string,
        mapper: (data: DictDataDef) => OptionType = data => ({
            label: data.dictDataName,
            value: data.dictDataValue,
            data,
        }),
        time: number = 5000
    ) => {
        let cacheType = tenantsId ? dictType + `-` + tenantsId : dictType;
        let cache = this.dicCache.get(cacheType);
        if (cache) {
            if (cache.completed) {
                return cache.data.map(mapper);
            } else {
                if (cache.loading) {
                    return new Promise<OptionType[]>((resolve, reject) => {
                        const timeOut = setTimeout(() => {
                            reject("获取字典信息失败！");
                        }, time);
                        // console.log(cache!.queue);
                        cache!.queue.push((data: DictDataDef[]) => {
                            clearTimeout(timeOut);
                            resolve(data.map(mapper));
                        });
                    });
                }
            }
        } else {
            cache = {
                loading: true,
                completed: false,
                data: [],
                queue: [],
            };
            this.dicCache.set(cacheType, cache);
        }
        try {
            cache.data = await this.getDictList(dictType, tenantsId);
            cache.completed = true;
            cache.queue.forEach(f => {
                try {
                    f(cache!.data);
                } catch (e) {
                    console.log(e);
                }
            });
        } finally {
            cache.loading = false;
            cache.queue.length = 0;
        }
        return cache.data.map(mapper);
    };

    clearDicCache = () => {
        this.dicCache.clear();
    };

    closeRoute = (route: RouteData) => {
        this.routerManager.closeRoute(route.fullPath);
        if (route.isSubApp) {
            this.microAppManager
                .getSubAppInst(route.name)
                ?.closeCacheRoute(route.fullPath);
        }
    };

    closeOtherRoute = (path: string) => {
        this.routerManager.closeOtherRoute(path);
    };

    loadIconFont(content: string[]) {
        const svg = document.createElement("div");
        svg.style.display = "none";
        svg.id = "appSvg";
        let html = "";
        content.forEach(c => {
            html += c;
        });
        svg.innerHTML = `<svg>${html}</svg>`;
        const app = document.getElementById("app");
        app!.parentNode!.insertBefore(svg, app);
    }

    get devTools() {
        return this.getService(DeveloperService);
    }

    get platformConfig() {
        return this.config as PlatformVueConfig<T>;
    }

    get uploadUrl() {
        return url_attachment.upload;
    }

    get downloadUrl() {
        return url_attachment.download;
    }

    private loadDevConfig(config: DevConfig) {
        if (!this.isDev.value) {
            this.isDev.value = true;
        }
        const devService = this.getService(DeveloperService);
        devService.devConfig = config;
        if (config.subApps) {
            this.devTools.addSubApps(config.subApps);
        }
        if (Array.isArray(config.subAppRoutes)) {
            config.subAppRoutes.forEach(route => {
                this.devTools.addSubAppMenuDef(route);
            });
        }
    }

    getMetaData(entityName: keyof typeof MetadataMap) {
        if (MetadataMap[entityName]) {
            return MetadataMap[entityName];
        } else {
            throw new Error(`can not find entity:${entityName}!`);
        }
    }

    getCheckActionFn = (permission: string) => {
        return (action: ActionTypes) =>
            this.permissionManager.checkAction(permission, action);
    };

    useEntity = <P = T>(entityName: keyof typeof Controllers, initData: P) => {
        return useEntity(
            this.observerAPI,
            this.getController<T, any>(entityName as string),
            { id: "", ...initData }
        );
    };

    upload = async (config: IRequestConfig, withToken: boolean = true) => {
        return this.getService(AttachmentService).upload(config, withToken);
    };

    download = async (
        config: IRequestConfig,
        optoins: {
            withToken: boolean;
            fileHeader: string;
            errMsg?: (result: IResponse) => Promise<string>;
        } = {
            withToken: true,
            fileHeader: "attachment-name",
        },
        fileName?: string
    ) => {
        return this.getService(AttachmentService).download(
            config,
            optoins,
            fileName
        );
    };

    getResourece: ResourceLoader = (options, ...params) => {
        return this.getService(ResourceService).getResource(options, ...params);
    };

    protected initLocale = () => {
        const localeService = this.getService(LocaleService);
        localeService.addLocaleLoader("ant-design-vue", antdLoader);
        localeService.addLocaleLoader("dayjs", dayJSLoader);
    };

    get currentLang() {
        return this.getService(LocaleService).currentLang.value;
    }
    set currentLang(val) {
        this.getService(LocaleService).currentLang.value = val;
    }

    getLocaleData = (key: string, lang: string) => {
        return this.getService(LocaleService).getLocale(key, lang);
    };
}

export const ObserverAPI: IObserver = {
    //@ts-ignore
    reactive,
    watch,
    computed,
    ref,
};
