import { SubAppEntity } from "@/entities/subApp/subAppEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_subApp } from "@/controllers/subApp/subAppUrls";
import { urlManger } from "@/url/common";
export class SubAppController extends EntityController<SubAppEntity> {
    constructor(request: IRequest) {
        super(request, url_subApp, "子应用");
    }
    static readonly entityName = "subApp";
    async subappDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/subapp/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async subappSave(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/subapp/save`,
            method: "post",
            applyLoading: true,
            data,
        });
        return res;
    }
    async subappUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/subapp/update`,
            method: "post",
            applyLoading: true,
            data,
        });
        return res;
    }
}
