import { Platform } from "@/config/platform";
import { ServerResponse } from "@/login/declare";
import { UploadRequestOption } from "@intasect/ant-design-vue/es/vc-upload/interface";
import {
    AuthRequestConfig,
    CallbackResolveType,
    CallbackType,
    EventDef,
    EventMap,
    EventType,
    generNetWorkErrorMessage,
    IRequest,
    IRequestConfig,
    IResponse,
    IService,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import fileDownload from "js-file-download";
import { FileResponse } from "./declare";

const AttachmentServiceType = Symbol.for("AttachmentService");

export enum AttachmentServiceEvents {
    importFileSuccess = "importFileSuccess",
    importFileFail = "importFileFail",
    exportFileSuccess = "exportFileSuccess",
    exportFileFail = "exportFileFail",
    downLoadSuccess = "downloadSuccess",
    downLoadFail = "downloadFail",
    uploadSuccess = "uploadSuccess",
    uploadFail = "uploadFail",
    acceptFail = "acceptFail",
}

const AttachmentServiceEventDefs: EventDef[] = [
    {
        eventName: AttachmentServiceEvents.importFileSuccess,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["info"],
    },
    {
        eventName: AttachmentServiceEvents.importFileFail,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err"],
    },
    {
        eventName: AttachmentServiceEvents.exportFileSuccess,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["info"],
    },
    {
        eventName: AttachmentServiceEvents.exportFileFail,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err"],
    },
    {
        eventName: AttachmentServiceEvents.downLoadSuccess,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err", "config"],
    },
    {
        eventName: AttachmentServiceEvents.downLoadFail,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err", "config"],
    },
    {
        eventName: AttachmentServiceEvents.uploadSuccess,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err", "config"],
    },
    {
        eventName: AttachmentServiceEvents.uploadFail,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err", "config"],
    },
    {
        eventName: AttachmentServiceEvents.acceptFail,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["err"],
    },
];

export class AttachmentService implements IService<never> {
    serviceData: never;
    isDev: RefType<boolean>;
    authRequest!: IRequest;
    noAuthRequest!: IRequest;
    protected eventMap!: EventMap;
    url: string;

    init = (platform: PlatformCore) => {
        this.authRequest = platform.authRequest;
        this.noAuthRequest = platform.noAuthRequest;
        this.isDev = platform.isDev;
        this.eventMap = platform.addEventDef(
            "attachmentService",
            AttachmentServiceEventDefs
        );
        this.url = (platform as Platform).uploadUrl;
    };

    upload = async (config: IRequestConfig, withToken: boolean = true) => {
        try {
            const request = withToken ? this.authRequest : this.noAuthRequest;
            const { headers, method, ...rest } = config;
            return await request<Blob>({
                method: method || "post",
                //@ts-ignore
                headers: {
                    "content-type": "multipart/form-data",
                    ...headers,
                },
                ...rest,
            });
        } catch (e) {
            this.events(AttachmentServiceEvents.uploadFail).dispatch([e]);
            return false;
        }
    };

    upload2 = async (options: UploadRequestOption): Promise<FileResponse> => {
        const formData = new FormData();
        formData.append("file", options.file);
        const result = await this.authRequest<ServerResponse<string>>({
            method: "post",
            url: this.url,
            data: formData,
            //@ts-ignore
            onUploadProgress: (prog: any) => {
                if (options.onProgress) {
                    options.onProgress({
                        percent: Math.round((prog.loaded * 100) / prog.total),
                    });
                }
            },
        });
        return {
            success: result.success,
            data: result.body,
            message: result.message || result.header.message,
        };
    };

    download = async (
        config: AuthRequestConfig,
        optoins: {
            withToken: boolean;
            fileHeader: string;
            errMsg?: (result: IResponse) => Promise<string>;
        } = {
            withToken: true,
            fileHeader: "attachment-name",
        },
        fileName?: string
    ) => {
        try {
            const request = optoins.withToken
                ? this.authRequest.raw
                : this.noAuthRequest.raw;
            const result = await request<Blob>({
                ...config,
                autoProcessError: false,
                responseType: "blob",
            });
            if (result.status === 200) {
                fileDownload(
                    result.data!,
                    fileName ||
                        decodeURI(
                            (result.headers[optoins.fileHeader] as string) ||
                                "download_file"
                        )
                );
                this.events(AttachmentServiceEvents.downLoadSuccess).dispatch([
                    result,
                    config,
                ]);
                return { success: true };
            } else {
                const message =
                    result.statusText ||
                    generNetWorkErrorMessage(result.status as any);
                this.events(AttachmentServiceEvents.downLoadFail).dispatch([
                    optoins.errMsg ? await optoins.errMsg(result) : message,
                    config,
                ]);
                return { success: false, message, result };
            }
        } catch (e: any) {
            this.events(AttachmentServiceEvents.downLoadFail).dispatch([
                e.message,
                config,
            ]);
            return { success: false, message: e };
        }
    };

    download2 = async (config: AuthRequestConfig, fileName?: string) => {
        const data = await this.authRequest.raw<Blob>({
            ...config,
            autoProcessError: false,
            responseType: "blob",
        });
        const downloadElement = document.createElement("a");
        const href = URL.createObjectURL(data.data!);
        downloadElement.href = href;
        if (fileName) {
            downloadElement.download = fileName;
        } else if (typeof data.headers["content-disposition"] === "string") {
            downloadElement.download = data.headers["content-disposition"]
                .split(";")[1]
                .split("=")[1]; // 自定义文件名
        }
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        return Promise.resolve();
    };

    events(eventsName: AttachmentServiceEvents) {
        return this.eventMap[eventsName];
    }
    static readonly iocType = AttachmentServiceType;
}
