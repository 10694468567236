import {
    IRequest,
    IService,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import { IResource, ResourceLoader } from "./declare";
import { resourceClass, resourceStatic } from "@/resource";

const ResourceServiceType = Symbol.for("ResourceService");

export type ResourceCodeType = "site";

export class ResourceService implements IService<Record<string, IResource>> {
    serviceData: Record<string, IResource>;
    isDev: RefType<boolean>;
    request!: IRequest;

    init = (platform: PlatformCore) => {
        this.request = platform.authRequest;
        this.isDev = platform.isDev;
        this.serviceData = {};
    };

    getResource: ResourceLoader = async (options, ...params) => {
        if (
            this.serviceData[options.resourceCode] === undefined &&
            resourceClass[options.resourceCode]
        ) {
            this.serviceData[options.resourceCode] = new resourceClass[
                options.resourceCode
            ](this.request);
        }
        if (this.serviceData[options.resourceCode]) {
            return this.serviceData[options.resourceCode].getResource(
                options,
                ...params
            );
        }
        if (resourceStatic[options.resourceCode]) {
            return resourceStatic[options.resourceCode];
        }
        throw new Error(`can't find resouce with '${options.resourceCode}'!`);
    };

    static readonly iocType = ResourceServiceType;
}
