import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_user: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/user/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/api/user/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("user")}/api/user/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/api/user/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/user/findUserPage`;
    },
    get list() {
        return `${urlManger.getServiceUrl("user")}/system/user/findUserPage`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("user")}/api/user/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("user")}/api/user/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("user")}/api/user/exportPdf`;
    },
};
export const url_user_role: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/user/findRoleBindingUserPage`;
    },
    get list() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/user/findRoleBindingUserPage`;
    },
};
export const url_site_role: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/user/findSiteBindingUsersPage`;
    },
    get list() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/user/findSiteBindingUsersPage`;
    },
};
