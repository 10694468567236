import { Metadata } from "@intasect/platform-core";
export const processingMessageMetadata: Metadata[] = [
    {
        fieldName: "msgId",
        fieldType: "string",
        title: "消息id"
    },
    {
        fieldName: "msgProcessType",
        fieldType: "string",
        title: "处理类型"
    },
    {
        fieldName: "msgProcessStatus",
        fieldType: "string",
        title: "处理状态"
    },
    {
        fieldName: "msgProcessResult",
        fieldType: "string",
        title: "处理结果"
    },
    {
        fieldName: "msgProcessTime",
        fieldType: "string",
        title: "处理时间"
    }
];
