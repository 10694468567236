import { LoginLogEntity } from "@/entities/loginLog/loginLogEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_loginLog } from "@/controllers/loginLog/loginLogUrls";
import { urlManger } from "@/url/common";
export class LoginLogController extends EntityController<LoginLogEntity> {
    constructor(request: IRequest) {
        super(request, url_loginLog, "登录日志");
    }
    static readonly entityName = "loginLog";
    async getLogInfo(data: any) {
        const res = await this.request({
            action: "",
            url: `${url_loginLog.page}`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
}
