import { urlManger } from "./common";

export const url_permission = {
    get get() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/menu/getAuthorizedMenuList`;
    },
    get getSubApp() {
        return `${urlManger.getServiceUrl('user')}/system/subapp/getAuthorizedSubApp`;//?terminalType=PC&userName=admin}
    }
}
export const url_user = {
    get login() {
        return `${urlManger.getServiceUrl("auth")}/oauth/getTokenByUser`;
    },
    get getUserInfo() {
        return `${urlManger.getServiceUrl("user")}/api/user/getUserInfo`;
    },
    get refreshToken() {
        return `${urlManger.getServiceUrl("auth")}/oauth/refreshToken`;
    },
    get register() {
        return `${urlManger.getServiceUrl("sublink")}/sl/tenants/common/register`;
    },
    get forget() {
        return `${urlManger.getServiceUrl("sublink")}/sl/tenants/common/password/forget`;
    },
    get reset() {
        return `${urlManger.getServiceUrl("sublink")}/sl/tenants/common/password/reset`;
    },
    get captcha() {
        return `${urlManger.getServiceUrl("auth")}/oauth/captcha`;
    },
    get registerCode() {
        return `${urlManger.getServiceUrl("auth")}/sl/tenants/common/register-code`;
    },
    get creditCodeCheck() {
        return `${urlManger.getServiceUrl("auth")}/sl/base/creditCode/check`;
    },
    get invitationinfo() {
        return `${urlManger.getServiceUrl("sublink")}/sl/sys/linked/platform/invitation/info`;
    },


};

export const url_attachment = {
    get upload() {
        return `${urlManger.getServiceUrl("user")}/system/file/upload`;
    },
    get download() {
        return `${urlManger.getServiceUrl("user")}/system/file/download`;
    },
};
