import { url_user } from "@/url/platform";
import { loginEncrypt } from "@/utils/Encrypt";
import {
    AuthDef,
    AuthRequestConfig,
    IGlobalStore,
    IRequest,
    IRequestConfig,
    IResponseError,
} from "@intasect/platform-core";
import { RequestResult, WebAuthData } from "./declare";
export class WebLoginManager extends AuthDef<WebAuthData> {
    retryInterval = 2000;
    maxRetry = 2;
    private expireTime = 0;
    constructor(private globalStore: IGlobalStore) {
        super();
        this.authData = JSON.parse(
            globalStore.getSession("WebLoginManagerAuthData") || "{}"
        );
        this.expireTime = parseInt(
            globalStore.getSession("WebLoginManagerAuthExpireTime") || "0"
        );
    }
    checkAuth = () => {
        return { success: this.expireTime > new Date().getTime() };
    };
    auth = async (request: IRequest) => {
        try {
            const data:any = await request<RequestResult<WebAuthData>>({
                method: "POST",
                headers: {
                    Authorization: `${this.authData.token_type} ${this.authData.access_token}`,
                },
                url: url_user.refreshToken,
                data: this.authData.refresh_token,
            });
            if (data.body.access_token) {
                this.initAuthData({ ...data.body, success: true });
            }
            return data;
        } catch {
            return { success: false };
        }
    };
    authReslover = (config: AuthRequestConfig) => {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers[
            "Authorization"
        ] = `${this.authData.token_type} ${this.authData.access_token}`;
        return config;
    };
    private initAuthData = (data: RequestResult<WebAuthData>) => {
        data.success = true;
        this.authData = data;
        switch (this.authData.token_type) {
            case "bearer":
                this.authData.token_type = "Bearer";
                break;
            default:
                break;
        }
        const expireTime = new Date().getTime() + data.expires_in * 1000;
        this.globalStore.setSession(
            "WebLoginManagerAuthData",
            JSON.stringify(data)
        );
        this.globalStore.setSession(
            "WebLoginManagerAuthExpireTime",
            expireTime
        );
        this.expireTime = expireTime;
    };
    login = async (row:any) => {
        try {
            const data: any = await this.noAuthRequest<RequestResult<WebAuthData>>({
                url: url_user.login,
                method: "post",
                data:{...row,
                    password: loginEncrypt(row.password),
                },
                autoProcessError: false,
            });
            if(data.header.code === 10001011){
                return data;
            }
            if (data.body&&data.body.access_token) {
                this.initAuthData(data.body);
            }
            return data;
        } catch (e: any) {
            const a = e as IResponseError & {
                config: IRequestConfig;
            };
            return {
                body: {
                    success: false,
                    message:
                        a.response?.data.header?.message || a.message || "未知错误",
                }
            };
        }
    };
    logout = () => {
        this.expireTime = 0;
        this.globalStore.setSession("WebLoginManagerAuthData", "{}");
        this.globalStore.setSession("WebLoginManagerAuthExpireTime", 0);
        this.globalStore.setSession("userName", "");
    };
    get token() {
        return this.authData.access_token;
    }
    get expir() {
        return parseInt(
            ((this.expireTime - new Date().getTime()) / 1000).toFixed(0)
        );
    }
    // test() {
    //     this.expireTime = 0;
    //     // this.authData.access_token = "";
    // }
}
