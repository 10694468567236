import { Metadata } from "@intasect/platform-core";
export const scriptMetadata: Metadata[] = [
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "企业"
    },
    {
        fieldName: "id",
        fieldType: "string",
        title: "id"
    },
    {
        fieldName: "modelCode",
        fieldType: "string",
        title: "脚本模型编码"
    },
    {
        fieldName: "modelName",
        fieldType: "string",
        title: "脚本模型名称"
    },
    {
        fieldName: "modelConfig",
        fieldType: "string",
        title: "脚本配置内容"
    }
];
