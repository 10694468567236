import { Metadata } from "@intasect/platform-core";
export const clientMetadata: Metadata[] = [
    {
        fieldName: "versionType",
        fieldType: "string",
        title: "版本类型",
    },
    {
        fieldName: "clientType",
        fieldType: "string",
        title: "客户端类型",
    },
    {
        fieldName: "tenantsCustom",
        fieldType: "string",
        title: "企业",
    },
    {
        fieldName: "appId",
        fieldType: "string",
        title: "应用ID",
    },
    {
        fieldName: "versionId",
        fieldType: "string",
        title: "版本号",
    },
    {
        fieldName: "remark",
        fieldType: "string",
        title: "更新内容",
    },
    {
        fieldName: "createTime",
        fieldType: "string",
        title: "发布时间",
    },
    {
        fieldName: "tenantsIdCustom",
        fieldType: "string",
        title: "企业",
    },
];
