import { MenusEntity } from "@/entities/menus/menusEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_menus } from "@/controllers/menus/roleMenusUrls";
import { urlManger } from "@/url/common";
export class RoleMenusController extends EntityController<MenusEntity> {
    constructor(request: IRequest) {
        super(request, url_menus, "菜单");
        this.actionMap = {
            ...this.actionMap,
            menuUpdate: `更新${this.entityTitle}`,
            menuDelete: `删除${this.entityTitle}`,
        };
    }
    static readonly entityName = "rolemenus";
}
