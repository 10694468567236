import { ClientEntity } from "@/entities/client/clientEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_client } from "@/controllers/client/clientUrls";
export class ClientController extends EntityController<ClientEntity> {
    constructor(request: IRequest) {
        super(request, url_client, "客户端");
    }
    static readonly entityName = "client";

    async publishClient(data: any): Promise<any> {
        return await this.request({
            url: url_client.publish,
            method: "post",
            data,
            applyLoading: true,
        });
    }

    async remove(id: string[]) {
        return await this.request({
            url: url_client.delete,
            method: "post",
            data: {
                id,
            },
        });
    }

    async queryVersionId(data: any) {
        return await this.request({
            url: url_client.versionId,
            method: "post",
            data,
        });
    }
}
