import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_tenants: EntityUrl<any> = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/save-or-update`;
    },
    get update() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/save-or-update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/page`;
    },
    get list() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/getTenantsTree`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/exportPdf`;
    },
    get tenantPermission() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/tenants/listHaveTenantsMenus`;
    },
    get updateTenantPermission() {
        return `${urlManger.getServiceUrl("user")}/system/tenants/bindingMenus`;
    },
};
