import { Metadata } from "@intasect/platform-core";
export const eventMetadata: Metadata[] = [
    {
        fieldName: "eventName",
        fieldType: "string",
        title: "事件名称"
    },
    {
        fieldName: "eventCode",
        fieldType: "string",
        title: "事件编码"
    },
    {
        fieldName: "eventDesc",
        fieldType: "string",
        title: "事件描述"
    },
    {
        fieldName: "orgId",
        fieldType: "string",
        title: "cmb企业id"
    },
    {
        fieldName: "enableStatus",
        fieldType: "string",
        title: "状态"
    },
    {
        fieldName: "eventModeName",
        fieldType: "string",
        title: "事件类型"
    },
    {
        fieldName: "callbackName",
        fieldType: "string",
        title: "是否有回调"
    },
    {
        fieldName: "callbackUrl",
        fieldType: "string",
        title: "回调地址"
    },
    {
        fieldName: "id",
        fieldType: "string",
        title: "ID"
    },
    {
        fieldName: "scriptName",
        fieldType: "string",
        title: "脚本名称"
    }
    
];
