
import { InviteregisterEntity } from "@/entities/inviteregister/inviteregisterEntity";
import { urlManger } from "@/url/common";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_inviteregister } from "./inviteregisterUrls";
export class InviteregisterController extends EntityController<InviteregisterEntity> {
    constructor(request: IRequest) {
        super(request, url_inviteregister, "邀请注册");
    }
    static readonly entityName = "inviteregister";
    async getTenantsTree(data: { tenantsName?: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async findDataRules(tenantsId: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("sublink")}/sl/sys/linked/platform/manager/` + tenantsId,
            method: "get",
            showSuccess: false,
        });
        return res;
    }
    async managerSave(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "sublink"
            )}/sl/sys/linked/platform/manager/save`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }

}
