import { urlManger } from "./common";

export const dictTypeApi = {
    get findSysDictTypePage(): string {
        return (
            urlManger.getServiceUrl("config") +
            "/system/dict-type/find-dict-type-page"
        );
    },
    get insertOrUpdate(): string {
        return (
            urlManger.getServiceUrl("config") +
            "/system/dict-type/insert-or-update"
        );
    },
    get updateDictStatus(): string {
        return (
            urlManger.getServiceUrl("config") +
            "/system/dict-type/update-dict-status"
        );
    },
    get deleteById(): string {
        return (
            urlManger.getServiceUrl("config") + "/system/dict-type/delete-by-id"
        );
    },
    get findList(): string {
        return urlManger.getServiceUrl("config") + "/system/dict-type/findList";
    },
    get findSysDictType() {
        return urlManger.getServiceUrl("user") + "/system/dict/findSysDictType";
    },
    get findSysDictTypeForMenu(): string {
        return (
            urlManger.getServiceUrl("config") +
            "/system/dict-type/findSysDictTypeForMenu"
        );
    },
};
