import { Metadata } from "@intasect/platform-core";
export const emailMetadata: Metadata[] = [
    {
        fieldName: "msgSubject",
        fieldType: "string",
        title: "邮件主题"
    },
    {
        fieldName: "senderTenantsId",
        fieldType: "string",
        title: "发送企业"
    },
    {
        fieldName: "receiverTenantsId",
        fieldType: "string",
        title: "接收企业"
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "邮件状态"
    },
    {
        fieldName: "createTime",
        fieldType: "date",
        title: "创建时间"
    },
    {
        fieldName: "sendTime",
        fieldType: "date",
        title: "发送时间"
    },
    {
        fieldName: "createTimeStart",
        fieldType: "string",
        title: "创建时间开始"
    },
    {
        fieldName: "createTimeEnd",
        fieldType: "string",
        title: "创建时间结束"
    },
    {
        fieldName: "sendTimeStart",
        fieldType: "string",
        title: "发送时间开始"
    },
    {
        fieldName: "sendTimeEnd",
        fieldType: "string",
        title: "发送时间结束"
    },
    {
        fieldName: "msgContent",
        fieldType: "string",
        title: "邮件内容"
    },
    {
        fieldName: "errMessage",
        fieldType: "string",
        title: "失败原因"
    },
    {
        fieldName: "email",
        fieldType: "string",
        title: "接收邮箱"
    },
    {
        fieldName: "senderTenantsName",
        fieldType: "string",
        title: "发送企业"
    },
    {
        fieldName: "receiverTenantsName",
        fieldType: "string",
        title: "接收企业"
    },
    {
        fieldName: "msgType",
        fieldType: "string",
        title: "邮件类型"
    },
    {
        fieldName: "retryCount",
        fieldType: "string",
        title: "发送次数"
    }
];
