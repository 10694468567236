import { subAppPrefix } from "@/config/subApp";
import { RouteRecordRaw } from "vue-router";
import { MenuDef, MenuType, VueRouteData } from "./declare";
import { defaultRouteEntry, lazyLoader } from "./RouterManager";

export const commonRoutes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "default",
        redirect: defaultRouteEntry,
    },
    {
        path: "/main",
        name: "main",
        component: () => import("../views/layout/BasicLayout.vue"),
        redirect: defaultRouteEntry,
        children: [],
    },
    {
        path: `/${subAppPrefix}/:pathMatch(.*)*`,
        name: "subApps",
        component: () => import("../views/layout/BasicLayout.vue"),
    },
    {
        path: "/loading",
        name: "loading",
        component: () => import("../views/common/loading.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/common/Login.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/common/register.vue"),
    },
    {
        path: "/forgetpassword",
        name: "forgetpassword",
        component: () => import("../views/common/forgetpassword.vue"),
    },
    {
        path: "/invite",
        name: "invite",
        component: () => import("../views/common/invite.vue"),
    },

];

const demoRoutes: VueRouteData[] = [
    {
        path: "parts",
        name: "parts",
        component: lazyLoader(() => import("../views/demo/parts.vue"), "parts"),
        meta: { id: "parts", title: "查询列表" },
    },
    {
        path: "form",
        name: "form",
        component: lazyLoader(
            () => import("../views/demo/iQueryForm.vue"),
            "form"
        ),
        meta: { id: "form", title: "筛选Demo" },
    },
    {
        path: "refer",
        name: "refer",
        component: lazyLoader(
            () => import("../views/demo/iRefer.vue"),
            "refer"
        ),
        meta: { id: "refer", title: "referDemo" },
    },
    {
        path: "image",
        name: "image",
        component: lazyLoader(
            () => import("../views/demo/IImage.vue"),
            "image"
        ),
        meta: { id: "image", title: "imageDemo" },
    },
    {
        path: "upload",
        name: "upload",
        component: lazyLoader(
            () => import("../views/demo/Upload.vue"),
            "upload"
        ),
        meta: { id: "upload", title: "uploadDemo" },
    },

    {
        path: "date",
        name: "date",
        component: lazyLoader(() => import("../views/demo/Date.vue"), "date"),
        meta: { id: "date", title: "时间Demo" },
    },
    {
        path: "alert",
        name: "alert",
        component: lazyLoader(() => import("../views/demo/alert.vue"), "alert"),
        meta: { id: "alert", title: "全局提示Demo" },
    },
    {
        path: "popconfirm",
        name: "popconfirm",
        component: lazyLoader(
            () => import("../views/demo/popconfirm.vue"),
            "popconfirm"
        ),
        meta: { id: "popconfirm", title: "气泡确认框" },
    },
    {
        path: "result",
        name: "result",
        component: lazyLoader(
            () => import("../views/demo/result.vue"),
            "result"
        ),
        meta: { id: "result", title: "结果" },
    },
    {
        path: "progress",
        name: "progress",
        component: lazyLoader(
            () => import("../views/demo/progress.vue"),
            "progress"
        ),
        meta: { id: "progress", title: "进度条" },
    },
    {
        path: "iTable_ex1",
        name: "iTable_ex1",
        component: lazyLoader(
            () => import("../views/demo/ITable_ex1.vue"),
            "iTable_ex1"
        ),
        meta: { id: "iTable_ex1", title: "ITable-复杂表格" },
    },
];

const systemRoutes: VueRouteData[] = [
    {
        path: "menus",
        name: "menus",
        component: lazyLoader(
            () => import("../views/menus/menus.vue"),
            "menus"
        ),
        meta: { id: "menus", title: "菜单" },
    },
    {
        path: "tenants",
        name: "tenants",
        component: lazyLoader(
            () => import("../views/tenants/tenants.vue"),
            "tenants"
        ),
        meta: { id: "tenants", title: "企业管理" },
    },
    {
        path: "department",
        name: "department",
        component: lazyLoader(
            () => import("../views/department/department.vue"),
            "department"
        ),
        meta: { id: "department", title: "部门管理" },
    },
    {
        path: "user",
        name: "user",
        component: lazyLoader(() => import("../views/user/user.vue"), "site"),
        meta: { id: "user", title: "用户管理" },
    },
    {
        path: "site",
        name: "site",
        component: lazyLoader(() => import("../views/site/site.vue"), "site"),
        meta: { id: "site", title: "据点管理" },
    },
    {
        path: "post",
        name: "post",
        component: lazyLoader(() => import("../views/post/post.vue"), "post"),
        meta: { id: "post", title: "岗位管理" },
    },
    {
        path: "role",
        name: "role",
        component: lazyLoader(() => import("../views/role/role.vue"), "role"),
        meta: { id: "role", title: "角色管理" },
    },
    {
        path: "subApp",
        name: "subApp",
        component: lazyLoader(
            () => import("../views/subApp/subApp.vue"),
            "subApp"
        ),
        meta: { id: "subApp", title: "子应用管理" },
    },
    {
        path: "client",
        name: "client",
        component: lazyLoader(
            () => import("../views/client/client.vue"),
            "client"
        ),
        meta: { id: "client", title: "客户端管理" },
    },
    {
        path: "setting",
        name: "setting",
        component: lazyLoader(
            () => import("../views/setting/setting.vue"),
            "setting"
        ),
        meta: { id: "setting", title: "系统设置" },
    },
    {
        path: "dictionary",
        name: "dictionary",
        component: lazyLoader(
            () => import("../views/dictionary/dictionary.vue"),
            "dictionary"
        ),
        meta: { id: "dictionary", title: "字典管理" },
    },
    {
        path: "login-log",
        name: "login-log",
        component: lazyLoader(
            () => import("../views/loginLog/loginLog.vue"),
            "loginLog"
        ),
        meta: { id: "loginLog", title: "登录日志" },
    },
    {
        path: "operation-log",
        name: "opreation-log",
        component: lazyLoader(
            () => import("../views/opreationLog/opreationLog.vue"),
            "loginLog"
        ),
        meta: { id: "opreationLog", title: "操作日志" },
    },
];

export const staticRoutes: VueRouteData[] = [
    {
        path: "home",
        name: "Home",
        component: lazyLoader(
            () =>
                import(
                    /* webpackChunkName: "home" */ "../views/common/Home.vue"
                ),
            "Home"
        ),
        meta: { title: `主页`, id: "home" },
    },
    {
        path: "about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoader(
            () =>
                import(
                    /* webpackChunkName: "about" */ "../views/common/About.vue"
                ),
            "About"
        ),
        meta: { id: "about", title: "关于" },
    },
    {
        path: "404",
        name: "404",
        component: lazyLoader(() => import("../views/common/404.vue"), "404"),
        meta: { id: "404", title: "404" },
    },
    ...demoRoutes,
    ...systemRoutes,
];

const demoMenus: MenuDef[] = [
    {
        id: "demo",
        title: "业务功能演示",
        parentId: "0",
        icon: "home",
        type: MenuType.list,
        order: 2,
        children: [],
        visible: "0",
    },
    {
        id: "parts",
        title: "查询列表",
        path: "parts",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 0,
        children: [],
        visible: "0",
    },
    {
        id: "form",
        title: "表格筛选",
        path: "form",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 3,
        children: [],
        visible: "0",
    },
    {
        id: "refer",
        title: "下拉框",
        path: "refer",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 4,
        children: [],
        visible: "0",
    },
    {
        id: "image",
        title: "图片",
        path: "image",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 5,
        children: [],
        visible: "0",
    },
    {
        id: "upload",
        title: "上传",
        path: "upload",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 6,
        children: [],
        visible: "0",
    },
    {
        id: "date",
        title: "时间",
        path: "date",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 7,
        children: [],
        visible: "0",
    },
    {
        id: "alert",
        title: "全局提示+抽屉",
        path: "alert",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 8,
        children: [],
        visible: "0",
    },
    {
        id: "popconfirm",
        title: "气泡确认框+标签+徽",
        path: "popconfirm",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 9,
        children: [],
        visible: "0",
    },
    {
        id: "result",
        title: "结果",
        path: "result",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 10,
        children: [],
        visible: "0",
    },
    {
        id: "progress",
        title: "进度条",
        path: "progress",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 11,
        children: [],
        visible: "0",
    },
    {
        id: "iTable_ex1",
        title: "ITable-复杂表格",
        path: "iTable_ex1",
        parentId: "demo",
        icon: "home",
        type: MenuType.list,
        order: 12,
        children: [],
        visible: "0",
    },
];

// const systemMenus: MenuDef[] = [
//     {
//         id: "tenants",
//         title: "企业管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 13,
//         children: [],
//     },
//     {
//         id: "department",
//         title: "部门管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 14,
//         children: [],
//     },
//     {
//         id: "user",
//         title: "用户管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 15,
//         children: [],
//     },
//     {
//         id: "post",
//         title: "岗位管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 16,
//         children: [],
//     },
//     {
//         id: "site",
//         title: "据点管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 17,
//         children: [],
//     },
//     {
//         id: "role",
//         title: "角色管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 18,
//         children: [],
//     },
//     {
//         id: "subApp",
//         title: "子应用管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 19,
//         children: [],
//     },
//     {
//         id: "client",
//         title: "客户端管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 20,
//         children: [],
//     },
//     {
//         id: "setting",
//         title: "系统设置",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 18,
//         children: [],
//     },
//     {
//         id: "dictionary",
//         title: "字典管理",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 18,
//         children: [],
//     },
//     {
//         id: "loginLog",
//         title: "登录日志",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 19,
//         children: [],
//     },
//     {
//         id: "opreationLog",
//         title: "操作日志",
//         parentId: "sys",
//         icon: "home",
//         type: MenuType.list,
//         order: 20,
//         children: [],
//     },
// ];

export const staticMenus: MenuDef[] = [
    {
        
        title: "主页",
        parentId: "0",
        icon: "HomeOutlined",
        type: MenuType.menu,
        path: "home",
        order: 0,
        children: [],
        visible: "0",
    },
    // {
    //     id: "about",
    //     title: "关于",
    //     path: "about",
    //     parentId: "0",
    //     icon: "home",
    //     type: MenuType.menu,
    //     order: 1,
    //     children: [],
    // },

    // {
    //     id: "sys",
    //     title: "系统管理",
    //     parentId: "0",
    //     icon: "home",
    //     type: MenuType.list,
    //     order: 2,
    //     children: [],
    // },
    // {
    //     id: "menus",
    //     title: "菜单管理",
    //     parentId: "sys",
    //     icon: "home",
    //     type: MenuType.list,
    //     order: 12,
    //     children: [],
    // },
    //...demoMenus,
    // ...systemMenus,
];
