import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_email: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl("sublink")}/system/email/page`;
    },
    get sendEmail() {
        return `${urlManger.getServiceUrl("sublink")}/system/email/`;
    },
};
