import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";

export const url_parts: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl("demo")}/api/part/page`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/part/delete`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/part/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/part/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/part/exportPdf`;
    },
    get save() {
        return `${urlManger.getServiceUrl("demo")}/api/part/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/part/update`;
    },
    get get() {
        return `${urlManger.getServiceUrl("demo")}/api/part/get`;
    },
    get list() {
        return `${urlManger.getServiceUrl("demo")}/api/part/list`;
    },
};
