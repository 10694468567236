import { EventEntity } from "@/entities/cmb/event/eventEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_event } from "@/controllers/cmb/event/eventUrls";
import { urlManger } from "@/url/common";
export class EventController extends EntityController<EventEntity> {
    constructor(request: IRequest) {
        super(request, url_event, "事件管理");
    }
    static readonly entityName = "event";
    async permissionList(tenantsId:string) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/request-permission/all/`+tenantsId,
            method: "get",

        });
        return res;

    }
    async eventAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/event/add`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"
        });
        return res;

    }
    async eventUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/event/update`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"
        });
        return res;

    }
    async delEvent(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/event/delEvent`,
            method: "post",
            data
        });
        return res;

    }
    async eventPull(eventId: string) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/event/pull/` + eventId,
            method: "get",

        });
        return res;

    }
    async eventPullUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/event/pull/update`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"

        });
        return res;

    }
    async getTenantsTree(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async getOtherOrgList(params: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/subscribe/getOtherOrgList/` + params.eventId + "/" + params.withSubscribe,
            method: "get"
        });
        return res;
    }
    async subscribeDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/subscribe/delete`,
            method: "post",
            data

        });
        return res;

    }
    async subscribeAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/subscribe/add`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"

        });
        return res;
    }
    async subscribeUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/subscribe/update`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"

        });
        return res;
    }
    async scriptAll(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/script/all`,
            method: "post",
            data,
          

        });
        return res;
    }


}
