import { Metadata } from "@intasect/platform-core";
export const loginLogMetadata: Metadata[] = [
    {
        fieldName: "loginUserName",
        fieldType: "string",
        title: "用户名",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "企业ID",
    },
    {
        fieldName: "loginTime",
        fieldType: "string",
        title: "操作时间",
    },
    {
        fieldName: "loginIp",
        fieldType: "string",
        title: "IP地址",
    },
];
