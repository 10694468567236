import { Metadata } from "@intasect/platform-core";
export const opreationLogMetadata: Metadata[] = [
    {
        fieldName: "operationUserName",
        fieldType: "string",
        title: "操作人",
    },
    {
        fieldName: "operationTime",
        fieldType: "string",
        title: "操作时间",
    },
    {
        fieldName: "operationType",
        fieldType: "string",
        title: "操作类型",
    },
    {
        fieldName: "operationDesc",
        fieldType: "string",
        title: "操作说明",
    },
    {
        fieldName: "operationIp",
        fieldType: "string",
        title: "IP地址",
    },
];
