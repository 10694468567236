import { Metadata } from "@intasect/platform-core";
export const dictionaryMetadata: Metadata[] = [
    {
        fieldName: "id",
        fieldType: "string",
        title: "ID",
    },
    {
        fieldName: "dictName",
        fieldType: "string",
        title: "字典名称",
    },
    {
        fieldName: "dictCode",
        fieldType: "string",
        title: "字典编码",
    },
    {
        fieldName: "remark",
        fieldType: "string",
        title: "备注",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "createTime",
        fieldType: "string",
        title: "创建时间",
    },
    {
        fieldName: "dictType",
        fieldType: "string",
        title: "字典类型",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "企业ID",
    },
    {
        fieldName: "isDefault",
        fieldType: "string",
        title: "是否默认字典",
    },
    {
        fieldName: "containsDefaults",
        fieldType: "boolean",
        title: "包含默认字典",
    },
];
