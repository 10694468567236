import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_script: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/script/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("demo")}/api/script/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/script/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/script/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/cmb/script/findPage`;
    },
    get list() {
        return `${urlManger.getServiceUrl("demo")}/api/script/list`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/script/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/script/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/script/exportPdf`;
    }
};
