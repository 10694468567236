import { Metadata } from "@intasect/platform-core";
export const dicdataMetadata: Metadata[] = [
    {
        fieldName: "id",
        fieldType: "string",
        title: "数据id",
    },
    {
        fieldName: "dictId",
        fieldType: "string",
        title: "字典id",
    },
    {
        fieldName: "dictDataName",
        fieldType: "string",
        title: "数据名称",
    },
    {
        fieldName: "dictDataCode",
        fieldType: "string",
        title: "数据编码",
    },
    {
        fieldName: "dictDataSort",
        fieldType: "number",
        title: "顺序",
    },
    {
        fieldName: "remark",
        fieldType: "string",
        title: "备注",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "queryData",
        fieldType: "string",
        title: "",
    },
    {
        fieldName: "dictDataValue",
        fieldType: "string",
        title: "数据值",
    },
    {
        fieldName: "createTime",
        fieldType: "string",
        title: "创建时间",
    },
];
