import { UserEntity } from "@/entities/user/userEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_site_role, url_user } from "@/controllers/user/userUrls";
import { url_user_role } from "@/controllers/user/userUrls";
import { urlManger } from "@/url/common";
import { AnyARecord } from "dns";
import { url_setting } from "../setting/settingUrls";
export class UserController extends EntityController<UserEntity> {
    constructor(request: IRequest) {
        super(request, url_user, "用户");
    }
    static readonly entityName = "user";
    async getTenantsTree(data: { tenantsName?: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async getDeptTree(data: {
        deptName?: string;
        tenantsId: string | number | undefined;
    }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/dept/getDeptTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async userDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async unlock(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("auth")}/oauth/unlockAccount`,
            method: "post",
            data,
        });
        return res;
    }
    async findPostTree(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/post/findPostTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async userAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/add`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    async userUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/update`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }

    async findUserById(data: { userId: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/findUserById`,
            method: "post",
            showSuccess: false,
            data: {
                id: data.userId,
            },
        });
        return res;
    }
    //获取角色组和关联角色信息  /isct-user-center/system/roleGroup/findRoleGroupInfo
    async findRoleGroupInfo(data: { id: string; tenantsId: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/roleGroup/findRoleGroupInfo`,
            method: "post",
            showSuccess: false,
            data,
        });
        return res;
    }
    async addUserRoles(data: { id: string; roleIds: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/addUserRoles`,
            method: "post",
            applyLoading: true,
            data,
        });
        return res;
    }
    //修改密码
    async resetPassword(data: { id: string; password: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/resetPassword`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //获取密码规则
    async getOrgPassRule(data: any) {
        const res = await this.request({
            action: "",
            url: url_setting.getOrgPassRule + "?tenantsId=" + data,
            method: "get",
            showSuccess: false,
        });
        return res;
    }
    //生成二维码
    async getQrCodeInfo(data: { id: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/getQrCodeInfo`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }

    async getTenantsPrefix(params: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getTenantsPrefix`,
            method: "get",
            params,
            showSuccess: false,
        });
        return res;
    }
    async batchImport(data: any) {
        const formdata = new FormData();
        for (const key in data) {
            if (key === "files") {
                for (var i = 0; i < data[key].length; i++) {
                    formdata.append(key, data[key][i]);
                }
            } else {
                formdata.append(key, data[key]);
            }
        }
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/user/batchImport`,
            method: "post",
            data: formdata,
            notAutoProcess: true,
            showSuccess: false,
        });
        return res;
    }

    //首页
    async getDataSum() {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "sublink"
            )}/sl/home/data-sum`,
            method: "get",
          
            showSuccess: false,
        });
        return res;
    }
    async getHome() {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "sublink"
            )}/sl/home/get`,
            method: "get",
          
            showSuccess: false,
        });
        return res;
    }
    //菜单
    async homeSaveOrUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "sublink"
            )}/sl/home/saveOrUpdate`,
            method: "post",
            data,
        });
        return res;
    }
    
    
    
}
export class RoleUserController extends EntityController<UserEntity> {
    constructor(request: IRequest) {
        super(request, url_user_role, "角色用户");
    }
    static readonly entityName = "roleuser";
    //为角色添加用户关联
    async roleBindingUsers(data: { roleId: string; userIds: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/role/roleBindingUsers`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //为角色添加用户关联
    async roleDelUser(data: { roleId: string; userIds: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/role/roleDelUser`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
}

export class SiteUserController extends EntityController<UserEntity> {
    constructor(request: IRequest) {
        super(request, url_site_role, "角色用户");
    }
    static readonly entityName = "siteuser";
    //为据点添加用户关联
    async siteBindingUsers(data: { siteId: string; userIds: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/site/siteBindingUsers`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //为据点删除用户关联
    async siteDelUser(data: { siteId: string; userIds: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/site/siteRemoveUsers`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
}
