import { Metadata } from "@intasect/platform-core";
export const userMetadata: Metadata[] = [
    {
        fieldName: "userName",
        fieldType: "string",
        title: "用户名称",
    },
    {
        fieldName: "nickName",
        fieldType: "string",
        title: "用户账号",
    },
    {
        fieldName: "idCard",
        fieldType: "string",
        title: "身份证号码",
    },
    {
        fieldName: "deptName",
        fieldType: "string",
        title: "部门",
    },
    {
        fieldName: "email",
        fieldType: "string",
        title: "邮箱地址",
    },
    {
        fieldName: "phoneNumber",
        fieldType: "string",
        title: "电话号码",
    },
    {
        fieldName: "sex",
        fieldType: "string",
        title: "性别",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "remark",
        fieldType: "string",
        title: "用户信息",
    },
    {
        fieldName: "userInfo",
        fieldType: "string",
        title: "用户信息",
    },
    {
        fieldName: "userType",
        fieldType: "string",
        title: "用户类型",
    },
    {
        fieldName: "roleNames",
        fieldType: "string",
        title: "角色",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "租户id",
    },
    {
        fieldName: "isLocked",
        fieldType: "string",
        title: "锁定状态",
    },
    
];
