import type { Platform } from "@/config/platform";
import { ServerResponse } from "@/login/declare";
import { url_user } from "@/url/platform";
import {
    AuthManagerEvents,
    IService,
    MultiAuthManager,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import { ref } from "vue";

export type UserServiceData = {
    is_super_admin: boolean;
    user: {
        email?: string;
        grantMark?: { value: string };
        id: string;
        idCard?: string;
        nickName?: string;
        phoneNumber?: string;
        sex?: string;
        status?: string;
        userName?: string;
        userType?: string;
    };
    role: Array<{
        id: string;
        remark: string;
        roleKey: string;
        roleName: string;
        roleSort: string;
        status: string;
    }>;
    dept: Array<{
        id: string;
        remark: string;
        roleKey: string;
        roleName: string;
        roleSort: string;
        status: string;
    }>;
    post: Array<{
        id: string;
        remark: string;
        roleKey: string;
        roleName: string;
        roleSort: string;
        status: string;
    }>;
    tenants: {
        id: string;
        tenantsName: string;
    };
};

const UserServiceType = Symbol.for("UserService");

export class UserService implements IService<UserServiceData> {
    isDev!: RefType<boolean>;
    private multiAuthManager!: MultiAuthManager;
    private _serviceData = ref<UserServiceData>({
        dept: [],
        user: {
            id: "",
            nickName: "",
        },
        post: [],
        tenants: { id: "", tenantsName: "" },
        is_super_admin: false,
        role: [],
    });

    init = (platform: PlatformCore) => {
        platform.multiAuthManager.authManager
            .event(AuthManagerEvents.authSuccess)
            .addEventListener(async () => {
                const userInfo = await this.getUserInfo(platform);
                if (userInfo.success) {
                    this._serviceData.value = userInfo.body;
                } else {
                    const p = platform as Platform;
                    p.messageError(userInfo.header.message, "获取用户信息失败");
                    p.multiAuthManager.logout();
                }
            });
        platform.multiAuthManager.authManager
            .event(AuthManagerEvents.authClear)
            .addEventListener(() => {
                this.clear();
            });
        this.multiAuthManager = platform.multiAuthManager;
        this.isDev = platform.isDev;
    };
    private clear = () => {
        this._serviceData.value = {
            dept: [],
            user: {
                id: "",
                nickName: "",
            },
            post: [],
            tenants: { id: "", tenantsName: "" },
            is_super_admin: false,
            role: [],
        };
    };

    private getUserInfo = async (platform: PlatformCore) => {
        const result = await platform.multiAuthManager.authRequest<
            ServerResponse<UserServiceData>
        >({
            url: url_user.getUserInfo,
            method: "post",
            data: {
                authInfoCategories: [
                    "user",
                    "role",
                    "dept",
                    "tenants",
                    "post",
                    "is_super_admin",
                ],
            },
        });
        return result;
    };

    login = async (data:any) => {
        const result = await this.multiAuthManager.login(data);
      
        return result;
    };

    logout = () => {
        this.multiAuthManager.logout();
    };

    get serviceData() {
        return this._serviceData.value;
    }

    get isAdmin() {
        return this.serviceData.is_super_admin;
    }

    get userName() {
        return this.serviceData.user.nickName;
    }

    get userId() {
        return this.serviceData.user.id;
    }

    get userType() {
        return this.serviceData.user.userType;
    }

    get tenantID() {
        return this.serviceData.tenants.id;
    }

    get email() {
        return this.serviceData.user.email;
    }

    get phoneNumber() {
        return this.serviceData.user.phoneNumber;
    }

    static readonly iocType = UserServiceType;
}
