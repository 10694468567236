import { PartEntity } from "@/entities/parts/entity";
import {
    EntityController,
    EntityData,
    IRequest,
    PageCondition,
    PageResonse,
    sleep,
} from "@intasect/platform-core";
import { url_parts } from "./partsUrls";

export class PartsController extends EntityController<PartEntity> {
    constructor(request: IRequest) {
        super(request, url_parts, "零件");
    }
    static readonly entityName = "parts";

    async page(
        data: PageCondition<Partial<PartEntity>>
    ): Promise<PageResonse<EntityData<PartEntity>>> {
        await sleep(1000);
        return {
            pageNum: data.pageNum,
            pageSize: data.pageSize,
            total: "200",
            content: new Array(data.pageSize).fill("").map((a, index) => {
                const id = data.pageSize * (data.pageNum - 1) + index;
                return {
                    id,
                    singleText: `s${id}`,
                    multiText: `mu${id}`,
                    number: id,
                    date: "2022-1-1",
                    time: "7:00",
                    dateTime: "2022-1-1 7:00",
                    radio: "r1",
                    check: "c1",
                    select: "s1",
                    multiSelect: "m1,m2",
                    tableSelect: "t1",
                    list: [{ id: "1" }, { id: "2" }, { id: "3" }],
                };
            }),
        };
    }
}
