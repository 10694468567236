import { Platform } from "@/config/platform";
import {
    CallbackResolveType,
    CallbackType,
    EventDef,
    EventMap,
    EventType,
    IService,
    PlatformCore,
    RefType,
} from "@intasect/platform-core";
import { Ref, ref, watch } from "vue";

const LocaleServiceType = Symbol.for("LocaleService");

export enum LocaleServiceEvents {
    languageChanged = "languageChanged",
}

const LocaleServiceEventDefs: EventDef[] = [
    {
        eventName: LocaleServiceEvents.languageChanged,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["info"],
    },
];

export class LocaleService implements IService<Record<string, any>> {
    serviceData: Record<string, Record<string, Record<string, any>>> = {};
    isDev: RefType<boolean>;
    localPath: string;
    defaultLang: string;
    localeLoader: Record<string, (lang: string) => Promise<any>> = {};
    currentLang: Ref<string>;
    protected eventMap!: EventMap;

    init = (platform: PlatformCore) => {
        this.isDev = platform.isDev;
        this.eventMap = platform.addEventDef(
            "LocaleService",
            LocaleServiceEventDefs
        );
        this.localPath = (platform as Platform).localePath;
        this.defaultLang =
            (platform as Platform).appConfig.defaultLang ||
            WEBPACK_GLOBAL.DEFAULT_LANGUAGE;
        this.currentLang = ref(this.defaultLang);
        watch(
            () => this.currentLang.value,
            lang => {
                this.events(LocaleServiceEvents.languageChanged).dispatch([
                    lang,
                ]);
            },
            {
                immediate: true,
            }
        );
    };

    addLocaleLoader = (key: string, loader: (lang: string) => Promise<any>) => {
        this.localeLoader[key] = loader;
    };

    getLocale = async (key: string, lang: string) => {
        if (this.serviceData[key] && this.serviceData[key][lang]) {
            return this.serviceData[key][lang];
        } else if (this.localeLoader[key]) {
            const data = await this.localeLoader[key](lang);
            if (!this.serviceData[key]) {
                this.serviceData[key] = {};
            }
            this.serviceData[key][lang] = data;
            return data;
        }
    };

    apllyLang = (lang: string) => {};

    events(eventsName: LocaleServiceEvents) {
        return this.eventMap[eventsName];
    }

    static readonly iocType = LocaleServiceType;
}
