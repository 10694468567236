<template>
    <ConfigProvider
        :locale="(locale as any)"
        :getPopupContainer="getPopupContainer"
    >
        <div v-show="loading" class="global-loading">
            <Spin />
        </div>
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
    </ConfigProvider>
</template>

<style lang="less">
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    > #app {
        height: 100%;
        margin: 0;
        padding: 0;
    }
}
.global-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);
}
</style>

<script lang="ts">
import watermark from './utils/watermark.js';
import { defineComponent, onMounted, ref, watch } from "vue";
import dayjs from "dayjs";
import { ConfigProvider, Spin } from "@intasect/ant-design-vue";
import { usePlatform } from "./utils/composables";
import { svgIncon } from "./assets/svg";
import { dayJSLangResolver } from "./locales/utils";

import weekOfYear from "dayjs/plugin/weekOfYear";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default defineComponent({
    components: {
        ConfigProvider,
        Spin,
    },
    setup() {
        //   watermark.set('测试环境')
        const appRootRef = ref(null);
        const getPopupContainer = function () {
            //@ts-ignore
            return document.ori_getElementById("app");
        };
        const platform = usePlatform();
        const locale = ref();
        watch(
            () => platform.currentLang,
            async lang => {
                platform
                    .getLocaleData("ant-design-vue", lang)
                    .then((data: any) => {
                        locale.value = data;
                        const dayJslang = dayJSLangResolver(lang);
                        platform
                            .getLocaleData("dayjs", dayJslang)
                            .then((data: any) => {
                                dayjs.locale(dayJslang, data);
                                Object.assign(
                                    locale.value.DatePicker.lang,
                                    data,
                                    {
                                        shortWeekDays: data.weekdaysMin,
                                        shortMonths: data.monthsShort,
                                    }
                                );
                            });
                    });
            },
            { immediate: true }
        );
        onMounted(() => {
            platform.loadRootIconFont([svgIncon]);
        });
        return {
            locale,
            getPopupContainer,
            appRootRef,
            loading: platform.globalLoading,
        };
    },
});
</script>
