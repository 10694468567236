import { RoleEntity } from "@/entities/role/roleEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_role } from "@/controllers/role/roleUrls";
import { urlManger } from "@/url/common";
export class RoleController extends EntityController<RoleEntity> {
    constructor(request: IRequest) {
        super(request, url_role, "角色");
    }
    static readonly entityName = "role";

    async postDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/role/delete`,
            method: "post",
            data,
        });
        return res;
    }

    async findMenuTree(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/menu/findMenuTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async listHaveRoleMenus(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/role/listHaveRoleMenus`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    //获取角色组信息(列表)
    async findRoleGroup(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/roleGroup/findRoleGroup`,
            method: "post",
            showSuccess: false,
            data,
        });
        return res;
    }
    //添加角色组
    async roleGroupAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/roleGroup/add`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //修改角色组
    async roleGroupUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/roleGroup/update`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //删除角色组
    async roleGroupDelete(data: { id: string[] }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/roleGroup/delete`,
            method: "post",
            data,
        });
        return res;
    }
    //添加角色
    async roleAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/role/add`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //修改角色
    async roleUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/role/update`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    //获取角色数据权限
    async findDataRules(params: { roleId: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/role/findDataRules`,
            method: "get",
            showSuccess: false,
            params,
        });
        return res;
    }
    //绑定或修改数据权限
    async bindingDataRules(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/role/bindingDataRules`,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    // 角色功能权限
    async getRoleMenus(roleId: string) {
        return await this.request({
            url: url_role.getRoleMenus,
            method: "get",
            params: {
                roleId,
            },
        });
    }
    async setRoleMenus(data: any) {
        return await this.request({
            url: url_role.setRoleMenus,
            method: "post",
            data,
            applyLoading: true,
        });
    }
}
