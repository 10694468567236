import { Metadata } from "@intasect/platform-core";
export const inviteregisterMetadata: Metadata[] = [
    {
        fieldName: "regTenantsName",
        fieldType: "string",
        title: "企业名称"
    },
    {
        fieldName: "regTenantsCode",
        fieldType: "string",
        title: "企业编号"
    },
    {
        fieldName: "regTenantsCreditCode",
        fieldType: "string",
        title: "统一社会信用代码"
    },
    {
        fieldName: "regTenantsTime",
        fieldType: "string",
        title: "企业注册时间"
    },
    {
        fieldName: "regTenantsRowTime",
        fieldType: "string",
        title: "获取权限时间"
    }
];
