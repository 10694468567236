import { MenusEntity } from "@/entities/menus/menusEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_menus } from "@/controllers/menus/menusUrls";
import { urlManger } from "@/url/common";
export class MenusController extends EntityController<MenusEntity> {
    constructor(request: IRequest) {
        super(request, url_menus, "菜单");
        this.actionMap = {
            ...this.actionMap,
            menuUpdate: `更新${this.entityTitle}`,
            menuDelete: `删除${this.entityTitle}`,
        };
    }
    static readonly entityName = "menus";
    async findSysDictTypeForMenu() {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/dict-type/findSysDictType?dictType=menu_terminal_type`,
            method: "post",
        });
        return res;
    }

    async menuUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/menu/saveOrUpdate`,
            method: "post",
            data,
            action: "menuUpdate",
            showSuccess: true,
            applyLoading: true,
        });
        return res;
    }
    async menuDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/menu/deleteById`,
            method: "post",

            data: data,
        });
        return res;
    }
    async getSubAppSelect(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/subapp/getSubAppSelect`,
            method: "post",
            data: data,
            showSuccess: false,
        });
        return res;
    }
}
