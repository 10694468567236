import { OpreationLogEntity } from "@/entities/opreationLog/opreationLogEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_opreationLog } from "@/controllers/opreationLog/opreationLogUrls";
import { urlManger } from "@/url/common";
export class OpreationLogController extends EntityController<OpreationLogEntity> {
    constructor(request: IRequest) {
        super(request, url_opreationLog, "操作日志");
    }
    static readonly entityName = "opreationLog";
    async getOperationType(data: any) {
        const res = await this.request({
            url: url_opreationLog.dicCode,
            method: "post",
            data,
        });
        return res;
    }
}
