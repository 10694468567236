import { SettingEntity } from "@/entities/setting/settingEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_setting } from "@/controllers/setting/settingUrls";
import { urlManger } from "@/url/common";
export class SettingController extends EntityController<SettingEntity> {
    constructor(request: IRequest) {
        super(request, url_setting, "系统设置");
    }
    static readonly entityName = "setting";
    async saveOrgPassRule(data: any) {
        const res = await this.request({
            action: "update",
            url: url_setting.savePass,
            method: "post",
            data,
            applyLoading: true,
            showSuccess: true,
        });

        return res;
    }

    async getPass(data: any) {
        const res = await this.request({
            action: "",
            url: url_setting.getPass,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async findPassSetting(params: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/setting/findPassSetting`,
            method: "get",
            params,
            showSuccess: false,
        });
        return res;
    }
    async getOrgPassRule(data: any) {
        const res = await this.request({
            action: "",
            url: url_setting.getOrgPassRule + "?tenantsId=" + data,
            method: "get",
            showSuccess: false,
        });
        return res;
    }

    async saveEmailInfo(data: any) {
        const res = await this.request({
            action: "update",
            url: url_setting.saveEmailInfo,
            method: "post",
            data,
            showSuccess: true,
        });
        return res;
    }
    async testSendEmail(data: any) {
        const res = await this.request({
            action: "test",
            url: url_setting.testSendEmail,
            method: "post",
            data,
            applyLoading: true,
        });
        return res;
    }
    async getEmailInfo(data: any) {
        const res = await this.request({
            action: "",
            url: url_setting.getEmailInfo + "?tenantsId=" + data,
            method: "get",
            showSuccess: false,
        });
        return res;
    }
    async getSystemInfo(data: any) {
        const res = await this.request({
            action: "",
            url: url_setting.getSystemInfo + "?tenantsId=" + data,
            method: "get",
            data,
            showSuccess: false,
        });
        return res;
    }
    async saveSystemInfo(data: any) {
        const res = await this.request({
            action: "update",
            url: url_setting.saveSystemInfo,
            method: "post",
            params: data,
            showSuccess: true,
        });
        return res;
    }
}
