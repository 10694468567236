import { MessageEntity } from "@/entities/cmb/message/messageEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_message } from "@/controllers/cmb/message/messageUrls";
import { urlManger } from "@/url/common";
export class MessageController extends EntityController<MessageEntity> {
    constructor(request: IRequest) {
        super(request, url_message, "消息管理");
    }
    static readonly entityName = "message";
    async getMessageParams(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/message/getMessageParams`,
            method: "post",
            data
        });
        return res;

    }
    async sendDeadMessage(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/message/sendDeadMessage`,
            method: "post",
            data
        });
        return res;

    }
    
}
