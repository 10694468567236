import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_dictionary: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/system/dict/findSysDict`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/system/dict/add`;
    },
    get update() {
        return `${urlManger.getServiceUrl("user")}/system/dict/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/system/dict/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/dict/findDictPage`;
    },
    get copy() {
        return `${urlManger.getServiceUrl("user")}/system/dict/duplicate`;
    },
};
