import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_post: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/post/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("user")}/api/post/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/post/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/post/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/findPostTree`;
    },
    get list() {
        return `${urlManger.getServiceUrl("user")}/system/post/findPostTree`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/post/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/post/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/post/exportPdf`;
    },
};
