import { ResourceBase, ResourceLoader } from "@/service/declare";
import { sleep } from "@intasect/platform-core";

export class DemoResource extends ResourceBase {
    getResource: ResourceLoader = async ({ keyword }) => {
        const now = new Date().getTime();
        await sleep(500);
        const list = [
            { label: `leaf-${now}`, value: `leaf-${now}`, isLeaf: false },
            { label: `node-${now}`, value: `node-${now}`, isLeaf: true },
        ];
        if (keyword) {
            return list.filter(a => a.label.includes(keyword));
        }
        return list;
    };
    static resourceCode = "demo";
}
