import { Metadata } from "@intasect/platform-core";
export const subAppMetadata: Metadata[] = [
    {
        fieldName: "id",
        fieldType: "string",
        title: "子应用ID",
    },
    {
        fieldName: "subAppCode",
        fieldType: "string",
        title: "子应用编码",
    },
    {
        fieldName: "subAppName",
        fieldType: "string",
        title: "子应用名称",
    },
    {
        fieldName: "subAppRoot",
        fieldType: "string",
        title: "子应用根地址",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
];
