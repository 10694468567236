import { OptionType } from "@/components/i-components/Presenters/declare";

const test: OptionType[] = [
    {
        label: "a",
        value: "a-1",
    },
    {
        label: "b",
        value: "b-1",
    },
];

export const resourceStatic: Record<string, OptionType[]> = {
    test,
};
