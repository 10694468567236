import { Platform } from "@/config/platform";
import { subAppPathPrefix, subAppPrefix } from "@/config/subApp";
import { PermissionSubAppRoleData } from "@/service/declare";
import {
    PermissionService,
    PermissionServiceEvents,
} from "@/service/permission";
import {
    EventMap,
    IMicroAppRegister,
    ISubApp,
    MicroAppCustomProps,
    MicroAppManagerEvents,
    PlatformCode,
    PlatformCore,
    SubAppDef,
} from "@intasect/platform-core";
import {
    registerMicroApps,
    start,
    setDefaultMountApp,
    loadMicroApp,
    FrameworkLifeCycles,
    FrameworkConfiguration,
    addGlobalUncaughtErrorHandler,
    LoadableApp,
} from "qiankun";
import { QianKunApp, RegistedApp } from "./declare";

export class MicroAppRegister
    implements IMicroAppRegister<PermissionSubAppRoleData>
{
    private microAppRegisterList: SubAppDef[] = [];
    private qianKunOptions: FrameworkConfiguration;
    private isMock: boolean = false;
    private started: boolean = false;
    private _activeAppCode: string;
    constructor(
        private platform: Platform,
        options: FrameworkConfiguration = {}
    ) {
        const {
            prefetch = "all",
            sandbox = { strictStyleIsolation: true },
            singular = false,
        } = options;
        this.qianKunOptions = {
            prefetch,
            sandbox,
            singular,
        };
        this.addPermissionChangeHandler =
            this.addPermissionChangeHandler.bind(this);
    }
    private activeCheck(location: Location, appCode: string) {
        const result =
            location.pathname.startsWith(
                `${
                    WEBPACK_GLOBAL.PUBLIC_PATH !== "/"
                        ? WEBPACK_GLOBAL.PUBLIC_PATH
                        : ""
                }/${subAppPrefix}/${appCode}/`
            ) ||
            this.platform.routerManager.cacheRoutes.some(
                a => a.name === appCode
            );
        if (result) {
            this._activeAppCode = appCode;
        }
        return result;
    }

    public genSubAppProps = (
        subAppCode: string,
        subAppRoot: string
    ): RegistedApp<MicroAppCustomProps<PermissionSubAppRoleData>> => {
        return {
            name: subAppCode,
            entry: subAppRoot,
            container: `#${subAppCode}`,
            activeRule: (location: Location) =>
                this.activeCheck(location, subAppCode),
            props: {
                rootPath: `${WEBPACK_GLOBAL.BASE_URL}${subAppPrefix}/${subAppCode}/`,
                subPath: `${subAppPathPrefix}${subAppCode}/`,
                platform: this.platform,
                subAppInfo: this.getSubAppInfo(subAppCode),
                isActive: this.buildSubAppActiveFn(subAppCode),
                checkPermission:
                    this.platform.permissionManager.checkPermission,
                checkAction: this.platform.permissionManager.checkAction,
                window,
                onPermissionChange: this.addPermissionChangeHandler,
                refreshToken: this.platform.multiAuthManager.refreshToken,
                setSubAppContainer: (name: string, container: ShadowRoot) => {
                    this.platform.microAppManager.setSubAppContainer(
                        name,
                        container
                    );
                },
                register: (subApp: ISubApp<any>) => {
                    const length = `${subAppPathPrefix}${subAppCode}`.length;
                    this.platform.microAppManager.addSubAppInst(subAppCode, {
                        ...subApp,
                        closeCacheRoute: path => {
                            subApp.closeCacheRoute(path.substring(0, length));
                        },
                    });
                    //@ts-ignore
                    if (subApp.id === PlatformCode) {
                        const subAppInfo = this.getSubAppInfo(subAppCode);
                        subAppInfo.def.isPlatform = true;
                        const container =
                            this.platform.microAppManager.getSubAppContainer(
                                subAppInfo.def.subAppCode
                            );
                        if (container) {
                            //@ts-ignore
                            container.getRootNode().host.className =
                                "isct-sub-app";
                        }
                    }
                },
                routeCache: this.platform.platformConfig.routeCache !== false,
            },
        };
    };

    private buildQiankunSubApps() {
        return this.microAppRegisterList.map<
            RegistedApp<MicroAppCustomProps<PermissionSubAppRoleData>>
        >(({ subAppCode, subAppRoot }) =>
            this.genSubAppProps(subAppCode, subAppRoot)
        );
    }

    private buildSubAppActiveFn(subAppCode: string) {
        return function () {
            return location.href.includes(`${subAppPathPrefix}${subAppCode}`);
        };
    }

    registerSubApps(subAppDefs: SubAppDef[], events: EventMap) {
        this.microAppRegisterList = subAppDefs;
        registerMicroApps(
            this.buildQiankunSubApps(),
            this.initLifecyle(events)
        );
        addGlobalUncaughtErrorHandler((event: unknown) => {
            console.error("microapp global error!!!!!", event);
        });
    }

    private getSubAppInfo = (subAppCode: string) => {
        const def = this.platform.microAppManager.getSubAppDef(subAppCode);
        const t = this;
        return {
            def,
            get permission() {
                return t.permissionRoleData.filter(a => a.subAppId === def.id);
            },
        };
    };

    addSubApps(defs: QianKunApp[], events: EventMap) {
        registerMicroApps(
            defs.map<
                RegistedApp<MicroAppCustomProps<PermissionSubAppRoleData>>
            >(({ name, entry }) => this.genSubAppProps(name, entry)),
            this.initLifecyle(events)
        );
    }

    private initLifecyle: (
        events: EventMap
    ) => FrameworkLifeCycles<MicroAppCustomProps<PermissionSubAppRoleData>> = (
        events: EventMap
    ) => ({
        beforeLoad: app => {
            events[MicroAppManagerEvents.beforeLoad].dispatch([app]);
            return Promise.resolve();
        },
        beforeMount: app => {
            events[MicroAppManagerEvents.beforeMount].dispatch([app]);
            return Promise.resolve();
        },
        beforeUnmount: app => {
            events[MicroAppManagerEvents.beforeUnmount].dispatch([app]);
            return Promise.resolve();
        },
        afterMount: app => {
            events[MicroAppManagerEvents.afterMount].dispatch([app]);
            return Promise.resolve();
        },
        afterUnmount: app => {
            events[MicroAppManagerEvents.afterUnmount].dispatch([app]);
            return Promise.resolve();
        },
    });

    findSubAppDef = (id: string) => {
        const result = this.microAppRegisterList.find(
            a => a.id === id || a.subAppCode === id
        );
        return result;
    };

    start() {
        if (!this.started) {
            start(this.qianKunOptions);
            this.started = true;
        }
    }
    get permissionRoleData(): PermissionSubAppRoleData[] {
        return this.platform.permissionManager.permission.serviceData;
    }

    get ActiveApp() {
        return this.platform.microAppManager.getSubAppDef(this._activeAppCode);
    }

    addPermissionChangeHandler(
        handler: (permissions: PermissionSubAppRoleData[]) => void
    ) {
        this.platform
            .getService(PermissionService)
            .events(PermissionServiceEvents.permissionChanged)
            .addEventListener((permissions: PermissionSubAppRoleData[]) => {
                handler(permissions);
            });
    }

    setDefaultMountApp(appLink: string) {
        setDefaultMountApp(appLink);
    }

    loadApp(app: LoadableApp<PlatformCore>) {
        loadMicroApp(app);
    }
}
