import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_opreationLog: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl("log")}/log/operation/page`;
    },
    get dicCode() {
        return `${urlManger.getServiceUrl("user")}/system/dict/findSysDictType`;
    },
};
