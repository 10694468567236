import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_inviteregister: EntityUrl = {
    get page() {
        return `${urlManger.getServiceUrl("sublink")}/sl/sys/linked/platform/page`;
    },
    get list() {
        return `${urlManger.getServiceUrl("sublink")}/sl/sys/linked/platform/page`;
    }
};
