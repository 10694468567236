import { Metadata } from "@intasect/platform-core";
export const roleMetadata: Metadata[] = [
    {
        fieldName: "roleName",
        fieldType: "string",
        title: "角色名称",
    },
    {
        fieldName: "roleKey",
        fieldType: "string",
        title: "角色编码",
    },
    {
        fieldName: "roleSort",
        fieldType: "string",
        title: "排序号",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "isDefault",
        fieldType: "number",
        title: "角色类型",
    },
    {
        fieldName: "roleGroupName",
        fieldType: "string",
        title: "角色组名称",
    },
    {
        fieldName: "roleGroupId",
        fieldType: "string",
        title: "角色组ID",
    },
];
