import { DepartmentEntity } from "@/entities/department/departmentEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_department } from "@/controllers/department/departmentUrls";
import { urlManger } from "@/url/common";
export class DepartmentController extends EntityController<DepartmentEntity> {
    constructor(request: IRequest) {
        super(request, url_department, "部门");
    }
    static readonly entityName = "department";
    async getTenantsTree(data: { tenantsName?: string }) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async deptDelete(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/dept/delete`,
            method: "post",
            data,
        });
        return res;
    }
    async getDeptTree(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/system/dept/getDeptTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async createOrUpdate(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/dept/createOrUpdate`,
            method: "post",
            data,
        });
        return res;
    }
}
