import { Metadata } from "@intasect/platform-core";
import { PartEntity, PartListEntity } from "./entity";

export const metaData: Metadata<PartEntity>[] = [
    {
        fieldName: "singleText",
        fieldType: "string",
        title: "单行文本",
    },
    {
        fieldName: "multiText",
        fieldType: "string",
        title: "多行文本",
    },
    {
        fieldName: "number",
        fieldType: "number",
        title: "数字",
    },
    {
        fieldName: "date",
        fieldType: "date",
        title: "日期",
    },
    {
        fieldName: "time",
        fieldType: "time",
        title: "时间",
    },
    {
        fieldName: "dateTime",
        fieldType: "dateTime",
        title: "日期时间",
    },
    {
        fieldName: "radio",
        fieldType: "string",
        title: "单选",
    },
    {
        fieldName: "check",
        fieldType: "string",
        title: "多选",
    },
    {
        fieldName: "select",
        fieldType: "string",
        title: "下拉",
    },
    {
        fieldName: "multiSelect",
        fieldType: "string",
        title: "下拉多选",
    },
    {
        fieldName: "tableSelect",
        fieldType: "string",
        title: "下拉表格",
    },
    {
        fieldName: "list",
        fieldType: "table",
        title: "表格",
        reference: 'partList'
    }
];

export const listMetaData: Metadata<PartListEntity>[] = [
    {
        fieldName: 'price',
        fieldType: 'number',
        title: '价格'
    },
    {
        fieldName: 'name',
        fieldType: 'string',
        title: '名称'
    }
]