import { ScriptEntity } from "@/entities/cmb/script/scriptEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_script } from "@/controllers/cmb/script/scriptUrls";
import { urlManger } from "@/url/common";
export class ScriptController extends EntityController<ScriptEntity> {
    constructor(request: IRequest) {
        super(request, url_script, "脚本");
    }
    static readonly entityName = "script";
    async getTenantsTree(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async saveOrUpdate(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/script/saveOrUpdate`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async scriptDelete(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/script/delete`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
    async scriptExecute(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/cmb/script/execute`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }
   
    
   
}
