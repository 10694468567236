import { ServerResponse } from "@/login/declare";
import { MenuDef, MenuType } from "@/router/declare";
import { url_permission } from "@/url/platform";
import {
    CallbackResolveType,
    CallbackType,
    EventDef,
    EventMap,
    EventType,
    IPermission,
    IRequest,
    PermissionData,
    PlatformCore,
    RefType,
    SubAppDef,
} from "@intasect/platform-core";
import { PermissionSubAppRoleData } from "./declare";

const PermissionServiceType = Symbol.for("PermissionService");
export enum PermissionServiceEvents {
    permissionChanged = "permissionChanged",
}

const PermissionServiceEventDefs: EventDef[] = [
    {
        eventName: PermissionServiceEvents.permissionChanged,
        eventType: EventType.async,
        callbackType: CallbackType.paralle,
        resolveType: CallbackResolveType.default,
        params: ["permissions"],
    },
];
export class PermissionService
    implements IPermission<PermissionSubAppRoleData[], SubAppDef[], MenuDef>
{
    isDev!: RefType<boolean>;
    private _serviceData: PermissionSubAppRoleData[] = [];
    public subAppDefs: SubAppDef[] = [];
    public readonly terminalType = "PC";
    public useMockPermission = false;
    protected eventMap!: EventMap;

    get serviceData() {
        return this._serviceData;
    }

    checkPermission = (perms: string) => {
        return !!this.getPerMissionData(perms);
    };

    checkAction = (permissionId: string, actionId: string) => {
        return !!this.getPerMissionData(actionId);
    };

    private getPerMissionData = (perms: string) => {
        return this.serviceData.find(a => a.perms === perms);
    };

    getModulePermission = async (
        request: IRequest
    ): Promise<PermissionData<PermissionSubAppRoleData[]>> => {
        if (this.useMockPermission) {
            this._serviceData = [];
            return {
                success: true,
                data: [
                    {
                        parentId: "parentId",
                        menuName: "menuName",
                        component: "list",
                        menuType: MenuType.menu,
                        perms: "perms",
                        orderNum: "0",
                        path: "/mockPath",
                        icon: "icon",
                        id: "id",
                        subAppId: "",
                    },
                ],
            };
        } else {
            try {
                const result = await request<
                    ServerResponse<PermissionSubAppRoleData[]>
                >({
                    method: "get",
                    url: url_permission.get,
                    params: {
                        terminalType: this.terminalType,
                    },
                });
                this._serviceData = result.body;
                return { success: result.success, data: this.serviceData };
            } catch (e: any) {
                return { success: false, message: e };
            }
        }
    };

    getSubAppPermission = async (request: IRequest) => {
        if (this.useMockPermission) {
            return { success: true, data: [] };
        } else {
            try {
                const result = await request<ServerResponse<SubAppDef[]>>({
                    method: "get",
                    url: url_permission.getSubApp,
                    params: {
                        terminalType: this.terminalType,
                        // accountName: this.userService.userName,
                    },
                });
                this.subAppDefs = result.body;
                return { success: result.success, data: result.body };
            } catch (e) {
                return { success: false, message: e as string };
            }
        }
    };

    init = (platform: PlatformCore) => {
        this.isDev = platform.isDev;
        platform.permissionManager.setPermission(this);
        this.eventMap = platform.addEventDef(
            "permissionService",
            PermissionServiceEventDefs
        );
    };

    addPermissions(...permissions: PermissionSubAppRoleData[]) {
        this.modeCheck();
        this.serviceData.push(...permissions);
        this.events(PermissionServiceEvents.permissionChanged).dispatch([
            this.serviceData,
        ]);
    }

    private modeCheck() {
        if (!this.isDev.value) {
            throw new Error("only can be used in devMode"!);
        }
    }

    events(eventsName: PermissionServiceEvents) {
        return this.eventMap[eventsName];
    }

    static readonly iocType = PermissionServiceType;
}
