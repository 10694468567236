import { urlManger } from "@/url/common";
import { ResourceBase, ResourceLoader } from "@/service/declare";
export class getSysTenantsTreeResource extends ResourceBase {
    getResource: ResourceLoader = async (
        { keyword },
    ) => {
        const reslut = await this.request({
            method: "post",
            url:`${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            data:{}
        });
        return (reslut.body[0]?.children ?? []).map<OptionType>(a => ({
            label: a.tenantsName,
            value: a.id,
        }));
    };
    static resourceCode = "getSysTenantsTree";
}