import { Metadata } from "@intasect/platform-core";
export const postMetadata: Metadata[] = [
    {
        fieldName: "id",
        fieldType: "string",
        title: "岗位ID",
    },
    {
        fieldName: "postCode",
        fieldType: "string",
        title: "岗位编码",
    },
    {
        fieldName: "postName",
        fieldType: "string",
        title: "岗位名称",
    },
    {
        fieldName: "orderNum",
        fieldType: "string",
        title: "排序",
    },
    {
        fieldName: "status",
        fieldType: "string",
        title: "状态",
    },
    {
        fieldName: "tenantsId",
        fieldType: "string",
        title: "租户id",
    },
];
