import { WhitelistEntity } from "@/entities/cmb/whitelist/whitelistEntity";
import { EntityController, IRequest } from "@intasect/platform-core";
import { url_whitelist } from "@/controllers/cmb/whitelist/whitelistUrls";
import { urlManger } from "@/url/common";
export class WhitelistController extends EntityController<WhitelistEntity> {
    constructor(request: IRequest) {
        super(request, url_whitelist, "企业白名单管理");
    }
    static readonly entityName = "whitelist";


    async romove(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/org-white-list/remove`,
            method: "post",
            showSuccess: false,
            data
        });
        return res;
    };

    async whiteListAdd(data: any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/org-white-list/add`,
            method: "post",
            data,
            showSuccess:true,
            action:"_default"
        });
        return res;

    }
   
    async getOtherOrgList(tenantId:any) {
        const res = await this.request({
            url: `${urlManger.getServiceUrl("user")}/cmb/org-white-list/getOtherOrgList/`+tenantId,
            method: "get",

        });
        return res;

    }
    async getTenantsTree(data: any) {
        const res = await this.request({
            action: "",
            url: `${urlManger.getServiceUrl(
                "user"
            )}/system/tenants/getSysTenantsTree`,
            method: "post",
            data,
            showSuccess: false,
        });
        return res;
    }

}
