import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_role: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("user")}/api/role/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("demo")}/api/role/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/role/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("demo")}/api/role/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl("user")}/system/role/findRolePage`;
    },
    get list() {
        return `${urlManger.getServiceUrl("user")}/system/role/findRolePage`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/role/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/role/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/role/exportPdf`;
    },
    get getRoleMenus() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/role/listHaveRoleMenus`;
    },
    get setRoleMenus() {
        return `${urlManger.getServiceUrl("user")}/system/role/bindingMenus`;
    },
};
