import { EntityService, ServiceList } from "@intasect/platform-core";
import { DeveloperService } from "./developer";
import { PermissionService } from "./permission";
import { ToDoService } from "./toDo";
import { UserService } from "./user";
import { ResourceService } from "./resource";
import { AttachmentService } from "./attachment";
import { SystemService } from "./system";
import { LocaleService } from "./locale";

export const mainAppServiceList: ServiceList =
    process.env.NODE_ENV === "development"
        ? [
              EntityService,
              UserService,
              SystemService,
              PermissionService,
              DeveloperService,
              ToDoService,
              ResourceService,
              AttachmentService,
              LocaleService,
          ]
        : [
              EntityService,
              UserService,
              SystemService,
              PermissionService,
              ToDoService,
              ResourceService,
              AttachmentService,
              LocaleService,
          ];
