// export const ServerIP = 'http://192.168.139.16:8086';
export const ServerIP = 'http://192.168.130.23:8086';
//'isct-user-center/system/role/findRolePage'
const serviceMap = {
    demo: "/isct-demo-service",
    config: "/isct-config",
    user: "/",
    auth: "/",
    workflow: "/",
    log: "/",
    sublink:"/"
};

export type commconServiceName = keyof typeof serviceMap;

class UrlManager {
    private serviceMap: Map<string, string> = new Map();
    constructor(
        public ServerIP: string,
        serviceMap: { [key: string]: string }
    ) {
        for (const key in serviceMap) {
            this.serviceMap.set(key, serviceMap[key]);
        }
    }
    getServiceUrl(serviceName: commconServiceName) {
        const result = this.serviceMap.get(serviceName);
        if (!result) {
            throw new Error(`can not find service '${serviceName}'`);
        }
        return `${this.ServerIP}`;
    }
}

export const urlManger = new UrlManager(ServerIP, serviceMap);
