import { emailMetadata as email } from "@/entities/email/email/emailMetadata";
import { inviteregisterMetadata as inviteregister } from "@/entities/inviteregister/inviteregisterMetadata";
import { scriptMetadata as script } from "@/entities/cmb/script/scriptMetadata";
import { processingMessageMetadata as processingMessage } from "@/entities/cmb/processingMessage/processingMessageMetadata";
import { messageMetadata as message } from "@/entities/cmb/message/messageMetadata";
import { subscribeMetadata as subscribe } from "@/entities/cmb/subscribe/subscribeMetadata";
import { eventMetadata as event } from "@/entities/cmb/event/eventMetadata";
import { configurationModelMetadata as configurationModel } from "@/entities/cmb/configurationModel/configurationModelMetadata";
import { whitelistMetadata as whitelist } from "@/entities/cmb/whitelist/whitelistMetadata";
import { opreationLogMetadata as opreationLog } from "@/entities/opreationLog/opreationLogMetadata";
import { loginLogMetadata as loginLog } from "@/entities/loginLog/loginLogMetadata";
import { dicdataMetadata as dicdata } from "@/entities/dicdata/dicdataMetadata";
import { dictionaryMetadata as dictionary } from "@/entities/dictionary/dictionaryMetadata";
import { settingMetadata as setting } from "@/entities/setting/settingMetadata";
import { clientMetadata as client } from "@/entities/client/clientMetadata";
import { subAppMetadata as subApp } from "@/entities/subApp/subAppMetadata";
import { roleMetadata as role } from "@/entities/role/roleMetadata";
import { userMetadata as user } from "@/entities/user/userMetadata";
import { userMetadata as roleuser } from "@/entities/user/userMetadata";
import { userMetadata as siteuser } from "@/entities/user/userMetadata";
import { postMetadata as post } from "@/entities/post/postMetadata";
import { departmentMetadata as department } from "@/entities/department/departmentMetadata";
import { siteMetadata as site } from "@/entities/site/siteMetadata";
import { menusMetadata as menus } from "@/entities/menus/menusMetadata";
import { menusMetadata as rolemenus } from "@/entities/menus/menusMetadata";
import { metaData as parts, listMetaData as partList } from "./parts/metadata";
import { tenantsMetadata as tenants } from "@/entities/tenants/tenantsMetadata";
export const MetadataMap = {
    email,
    inviteregister,
    script,
    processingMessage,
    message,
    subscribe,
    event,
    configurationModel,
    whitelist,
    opreationLog,
    loginLog,
    dicdata,
    dictionary,
    setting,
    client,
    subApp,
    role,
    user,
    post,
    menus,
    parts,
    tenants,
    department,
    site,
    roleuser,
    rolemenus,
    siteuser,
    partList
};

