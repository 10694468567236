import { Store, StoreOptions, createStore } from "vuex";
import { App } from "vue";
import { IGlobalStore } from "@intasect/platform-core";

export class StoreManager<T> implements IGlobalStore {
    public store: Store<T & { __globalStore: { [key: string]: any } }>;
    constructor(vue: App, options: StoreOptions<T>) {
        this.store = createStore<T & { __globalStore: { [key: string]: any } }>(
            {
                ...options,
                //@ts-ignore
                state() {
                    let s: T | undefined;
                    if (options.state !== undefined) {
                        if (typeof options.state === "function") {
                            //@ts-ignore
                            s = options.state();
                        } else {
                            s = options.state;
                        }
                    }
                    return {
                        ...s,
                        __globalStore: {},
                    };
                },
            }
        );
        vue.use(this.store);
    }
    getKey(key: string) {
        return this.store.state.__globalStore[key];
    }
    setKey(key: string, value: any): void {
        this.store.state.__globalStore[key] = value;
    }
    getKeyCache(key: string) {
        return localStorage.getItem(key);
    }
    setKeyCache(key: string, value: any): void {
        localStorage.setItem(key, value);
    }
    getSession(key: string) {
        return sessionStorage.getItem(key);
    }
    setSession(key: string, value: any) {
        sessionStorage.setItem(key, value);
    }
    clear() {
        this.store.state["__globalStore"] = {};
    }
}
