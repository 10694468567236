import { urlManger } from "@/url/common";
import { EntityUrl } from "@intasect/platform-core";
export const url_client: EntityUrl = {
    get get() {
        return `${urlManger.getServiceUrl("demo")}/api/client/get`;
    },
    get save() {
        return `${urlManger.getServiceUrl("demo")}/api/client/save`;
    },
    get update() {
        return `${urlManger.getServiceUrl("demo")}/api/client/update`;
    },
    get delete() {
        return `${urlManger.getServiceUrl("user")}/system/clientManager/delete`;
    },
    get page() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/clientManager/findPage`;
    },
    get list() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/clientManager/findPage`;
    },
    get importExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/client/importExcel`;
    },
    get exportExcel() {
        return `${urlManger.getServiceUrl("demo")}/api/client/exportExcel`;
    },
    get exportPdf() {
        return `${urlManger.getServiceUrl("demo")}/api/client/exportPdf`;
    },
    get publish() {
        return `${urlManger.getServiceUrl("user")}/system/clientManager/add`;
    },
    get versionId() {
        return `${urlManger.getServiceUrl(
            "user"
        )}/system/clientManager/findVersionId`;
    },
};
